.bonus {
	&-promo {
		&-btns {
			margin-block-end: 10px;
		}
		&-btn {
			color: #909298;
			&.active {
				color: #fff;
			}
			&:not(:last-child) {
				margin-inline-end: 20px;
			}
		}
		&-input {
			width: 340px;
		}
		&-row {
			display: flex;
			align-items: flex-end;
			
		}
		&-blockBtn {
			position: relative;
			.theme-btn {
				position: absolute;
				top: 0;
				right: 4px;
				bottom: 0;
				margin: auto 0;
				padding: 0 20px;
				height: 32px;
				font-size: 14px;
			}
			.theme-input {
				padding-right: 185px;
			}
		}
		&-show {
			display: flex;
			position: relative;
			align-items: flex-end;
			bottom: 3px;
			.inputGroup {
				width: 200px;
				flex-shrink: 0;
				flex-grow: 0;
				margin-inline-end: 20px;
			}
		}
	}
}

@media screen and (max-width: 880px) {
	.bonus-promo-show {
		display: grid;
		grid-template-columns: 2fr 1fr;
		grid-gap: 15px;
	}
	.bonus-promo-show .inputGroup {
		width: 100%;
		margin-inline-end: 0;
	}
	.bonus-promo-blockBtn {
		width: 100%;
	}
}

@media screen and (max-width: 520px) {
	.bonus-promo-row {
		.cabinet-blockBtn {
			width: 100%;
			flex-direction: column;
			align-items: flex-start;
			.theme-input {
				width: 100%;
			}
		}
	}
	.bonus-promo-show {
		grid-template-columns: 1fr;
	}
	.bonus-promo-blockBtn {
		position: static;
		.theme-btn {
			position: static;
			margin-top: 15px;
			width: 100%;
			height: 40px;
		}
	}
	.bonus-promo-blockBtn .theme-input {
		padding-right: 0;
	}
}
.cursor-disable {
	cursor: not-allowed;
}

.info-btn {
	background: var(--theme-color);
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 14px;
	padding: 0 12px;
	height: 40px;
	min-width: 70px;
	width: 100%;
	position: relative;
	transition: 0.2s;
}

.info-mofal {
	background-color: white;
	.ant-modal-body {
		padding: 1rem;
		h1{
			margin: 0;
		}
		p{
			margin: 0;
		}
		.heading {
			font-size: 25px;
			font-weight: 20px;
		}
		.subheading{
			font-size: 14px;
			font-weight: 400;
		}
	}

}