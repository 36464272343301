* {
	margin: 0;
	padding: 0;
  box-sizing: border-box;
}

html,
body {
	width: 100% !important;
	background: #0D1013;
}

body {
	max-width: 100%;
	width: 100%;
	margin: 0 auto;
	overflow: auto;
	overflow-x: hidden;
	padding-right: 0;
	
}
.main-navbar { //todo:kris а надо ли оно вообще?
	// max-width: 1664px;
	// width: 100%;
}

button:focus,
input:focus {
  outline: none;
}

button {
	cursor: pointer;
}

img {
	display: block;
}

html {
	scrollbar-color: #F0B25E var(--input-background);
	::-webkit-scrollbar {
		width: 4px;
	}
	::-webkit-scrollbar-track {
		background: var(--input-background);
	}
	::-webkit-scrollbar-thumb {
		background: #F0B25E;
		border-radius: 5px;
	}
}

@media screen and (min-width: 1920px) {
	html {
		background: var(--sidebar-block-bg);
	}
}