.cabinetForm {
	height: 100%;
	display: grid;
	grid-template-rows: 85px 1fr auto;
	
	&-title {
		font-size: 21px;
		color: var(--classic-color);
		margin-block-end: 30px;
	}
	&-rightTitle {
		font-size: 16px;
		color: var(--classic-color);
		background: #0D1013;
		padding: 24px 30px;
	}
	&-form {
		padding: 30px;
	}
	
	&-btn {
		margin-block-start: 20px;
		padding: 0 30px 30px;
	}
	.inputGroup {
		&:not(:last-child) {
			margin-block-end: 20px;
		}
	}
	&-text {
		color: #909298;
		font-size: 16px;
		margin-top: 30px;
	}
}


.withdrawForm {
	&-balance {
		margin-block-end: 10px;
		padding-block-end: 10px;
		&:not(:last-child) {
			border-bottom: 1px solid rgba(255, 255, 255, .1);
		}
		&-row {
			display: flex;
			justify-content: space-between;
			p {
				margin: 0;
				&:first-child {
					padding-inline-end: 15px;
				}
			}
		}	
		&-title {
			color: var(--classic-color);
			font-size: 18px;
			text-transform: capitalize;
		}
		&-available {
			font-size: 16px;
			color: var(--green-text);
		}
		&-unused {
			font-size: 12px;
			color: #C10000;
		}
	}
	&-total {
		display: flex;
		// align-items: center;
		justify-content: space-between;
		margin-block-start: 20px;
		margin-block-end: 50px;
		p {
			margin: 0;
			font-size: 18px;
			color: #fff;
			&:first-child {
				padding-inline-end: 15px;
			}
		}
	}
	&-amount {
		display: grid;
		grid-template-columns: 1fr auto;
		grid-gap: 10px;
	}
}



@media screen and (max-width: 1550px) {
	.cabinetForm-title {
		font-size: 19px;
		margin-block-end: 25px;
	}
}

@media screen and (max-width: 1320px) {
	.withdrawForm-balance-title {
		font-size: 16px;
	}
	.cabinetForm-form {
		padding: 20px;
	}
}

@media screen and (max-width: 1150px) {
	.cabinetForm-btn {
		margin-block-start: 30px;
	}
	.withdrawForm-total p {
		font-size: 16px;
	}
	.withdrawForm-amount {
		grid-template-columns: 1fr;
	}
	.withdrawForm-total {
		margin-block-end: 30px;
	}
}

@media screen and (max-width: 991px) {
	.withdrawForm-amount {
		grid-template-columns: 1fr auto;
	}
}

@media screen and (max-width: 725px) {
	.cabinet-block {
		.hide-on-mobile {
			display: none;
		}
	}
	.cabinetForm {
		display: block;
	}
	.cabinetForm-text {
		font-size: 14px;
		margin-bottom: 25px;
	}
}

@media screen and (max-width: 580px) {
	.cabinetForm-block {
		background: transparent;
		padding: 0;
		box-shadow: none;
	}
}

@media screen and (max-width: 500px) {
	.withdrawForm-total p,
	.withdrawForm-balance-available,
	.withdrawForm-balance-title {
		font-size: 14px;
	}
}

@media screen and (max-width: 360px) {
	.withdrawForm-amount {
		grid-template-columns: 1fr;
	}
}