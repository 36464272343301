.password-icon {
	width: 16px !important;
	height: auto;
}
.theme-dropdown {
  border-radius: 4px;
  .ant-dropdown-menu {
		background: var(--header-search-bg);
    padding: 0;
  }
  .ant-dropdown-menu-title-content {
    color: var(--classic-color);
    font-size: 14px;
  }
  .ant-dropdown-menu-item {
    padding: 0 25px;
    &:hover {
      background-color: transparent;
    }
    &:not(:last-child) {
      button {
        &:before {
          content: "";
          position: absolute;
          bottom: 0;
          inset-inline-start: 0;
          width: 100%;
          height: 1px;
          opacity: 0.1;
          background: var(--classic-color);
        }
      }
    }
  }
  .ant-dropdown-menu-title-content {
    button {
      padding: 10px 0;
      display: flex;
      align-items: center;
      width: 100%;
      transition: 0.2s;
      position: relative;
      padding-inline-end: 38px;
      img {
        display: block;
        margin-inline-end: 6px;
      }
    }
  }
}





.ant-input-prefix {
  margin-inline-end: 10px;
  width: 20px;
  display: flex;
  align-items: center;
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}


.phone-input {
  box-shadow: var(--login-input-active-autofill) !important;
  -webkit-box-shadow: var(--login-input-active-autofill) !important;
  -webkit-text-fill-color: var(--classic-color);
}
.ant-form-item-explain-error {
  font-size: 12px;
}

.input-error {
  color: #ff4d4f;
  font-size: 12px;
  line-height: 1.7;
  margin-block-start: 3px;
}

.ant-empty-description {
  color: var(--classic-color);
}

.ant-select-selection-placeholder,
.ant-picker-input > input::placeholder {
  font-size: 14px;
  @media screen and (max-width: 500px) {
    font-size: 12px;
  }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
}

.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 100%;
}

.ant-input {
  border: 0;
  background: none;
}

.ant-select-item-option {
  font-weight: 400 !important;
  background: transparent !important;
  color: var(--classic-color);
  &:hover {
    background: transparent !important;
  }
}

.ant-select-item,
.ant-select-item-empty {
  padding: 13px 12px;
}

.ant-select-dropdown {
  background-color: var(--header-search-bg);
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: var(--classic-color);
}

.ant-select,
.ant-select-selection-placeholder {
  display: flex;
  align-items: center;
}
.ant-select-selection-item {
  display: flex;
  align-items: center;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: transparent !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-selector {
  border: 0 !important;
}

.profile .ant-picker-suffix {
  margin-inline-end: 0;
}
.register .ant-picker-input {
  //datepicker
  flex-direction: row-reverse;
}
.ant-picker {
  background: var(--input-background) !important;
  box-shadow: none !important;
  border-radius: 5px;
  height: 40px;
  display: flex;
  border: 1px solid var(--input-border-color);
  &.no-border {
    border: 0 !important;
  }
  .anticon svg {
    width: 18px;
    height: 18px;
    path {
      fill: #B7B7B7;
    }
  }
}
.ant-picker-input > input {
  color: var(--classic-color);
  font-size: 14px;
}
.ant-picker-suffix {
  margin-inline-start: 0;
  margin-inline-end: 15px;
}

.ant-alert {
  padding: 0 5px !important;
}

.ant-select-arrow {
	color: #fff;
}

.ant-switch-checked {
	background-color: var(--theme-color);
}

.ant-table-wrapper {
	min-height: 250px;
}

@media screen and (max-width: 350px) {
  .ant-input-prefix {
    margin-inline-end: 10px;
    width: 15px;
  }
}
