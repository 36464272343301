.deposit {
  &__heading {
    padding: 30px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 15px;
    align-items: center;
    &-text {
      color: #fff;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0.042px;
      margin-bottom: 0;
    }
    &-icon {
      filter: drop-shadow(0px 0px 16px rgba(238, 176, 94, 0.64));
      border-radius: 50%;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      svg {
        width: 90%;
        height: 90%;
        object-fit: contain;
      }
      &:before {
        content: "";
        position: absolute;
        width: 110%;
        height: 110%;
        left: -5%;
        top: -5%;
        border-radius: 50%;
        background: linear-gradient(115deg, #be8d46 16.64%, #97672e 37.09%, #c99149 48.04%, #f0b25e 57.53%, #ffbe66 62.64%, #eeaf5d 68.48%, #c08944 80.17%, #97672e 89.66%);
      }
      .svg-block {
        position: relative;
        background: #161a1c;
        border-radius: 50%;
        width: 96%;
        height: 96%;
      }
    }
  }
  &__container {
    padding: 30px;
  }
  &__block {
    padding: 30px;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: #0d1013;
    box-shadow: 0px 0px 14.5px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: 35px;
    .deposit__heading-text {
      max-width: 840px;
      margin-bottom: 30px;
    }
  }
  &__wallets {
    margin-bottom: 30px;
    &-item {
      border-radius: 10px;
      background: #15191d;
      box-shadow: 0px 0px 14.5px 0px rgba(0, 0, 0, 0.25);
      padding: 20px;
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 20px;
      align-items: center;
      &:not(:first-child) {
        margin-top: 20px;
      }
    }
    &-network {
      color: #fff;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 0;
    }
    &-icons {
      margin-inline-start: 10px;
      margin-inline-end: 15px;
      display: flex;
      align-items: center;
      svg,
      img {
        height: 26px;
        width: auto;
        flex-shrink: 0;
        flex-grow: 0;
        object-fit: contain;
      }
    }
    &-currencies {
      color: #fff;
      font-size: 18px;
      font-weight: 300;
      margin-bottom: 0;
    }
    &-address {
      color: #fff;
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 0;
      align-items: center;
      display: grid;
      grid-template-columns: auto 1fr;
      text-align: left;
      transition: 0.15s;
      svg {
        margin-inline-start: 10px;
        width: 17px;
        height: auto;
        path {
          transition: 0.15s;
        }
      }
      p {
        margin-bottom: 0;
        word-break: break-all;
      }
      @media (hover: hover) {
        &:hover:not(.no-hover) {
          color: #eeaf5d;
          svg path {
            fill: #eeaf5d;
          }
        }
      }
    }
    &-left {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }
  &__withdrawText {
    color: #fff;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.042px;
  }
  &__slider {
    border-radius: 12px;
    border: 1px solid #e5a758;
    background: #151515;
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.48);
    overflow: hidden;
    &-slide {
      height: 325px;
      width: 100%;
      object-fit: cover;
      object-position: left;
      &.slide-right {
        object-position: right;
      }
      &.slide-center {
        object-position: center;
      }
      &.slide-mobile {
        display: none;
      }
    }
    .swiper-pagination {
      bottom: 20px;
    }
  }
}

@media screen and (max-width: 1320px) {
  .deposit__heading,
  .deposit__container,
  .deposit__block {
    padding: 20px;
  }
  .deposit__block,
  .deposit__block .deposit__heading-text,
  .deposit__wallets {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 991px) {
  .deposit__heading-text {
    font-size: 18px;
  }
}

@media screen and (max-width: 870px) {
  .deposit__slider-slide {
    height: 470px;
    object-position: center !important;
    &.slide-desktop {
      display: none;
    }
    &.slide-mobile {
      display: block;
    }
  }
}

@media screen and (max-width: 850px) {
  .deposit__wallets-item {
    grid-template-columns: 1fr;
  }
  .deposit__wallets-left {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 0 10px;
  }
  .deposit__wallets-network {
    order: 1;
    font-size: 16px;
  }
  .deposit__wallets-currencies {
    order: 3;
    grid-column-start: 1;
    grid-column-end: 3;
    font-size: 16px;
  }
  .deposit__wallets-icons {
    order: 2;
  }
  .deposit__wallets-icons {
    margin: 0;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 654px) {
  .deposit__slider-slide {
    height: 58vw;
  }
}

@media screen and (max-width: 555px) {
  .deposit__wallets-currencies,
  .deposit__wallets-network {
    font-size: 14px;
  }
  .deposit__heading-text {
    font-size: 16px;
  }
  .deposit__heading-icon {
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 370px) {
  .deposit__wallets-item,
  .deposit__block {
    padding: 13px;
  }
}
