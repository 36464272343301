@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");


body {
	font-family: "Inter", sans-serif;
}

#root {
	--sidebar-bg: linear-gradient(0deg, rgba(255, 255, 255, 0.02) 0%, rgba(255, 255, 255, 0.02) 100%), #0D1013;
  --sidebar-block-bg: #0D1013;
	--sidebar-block-border: 1px solid rgba(255, 255, 255, 0.08);
	--sidebar-block-border-radius: 6px;
  --sidebar-link-color: #B7B7B7;
	--header-bg: linear-gradient(0deg, rgba(255, 255, 255, 0.02) 0%, rgba(255, 255, 255, 0.02) 100%), #0D1013;
	--card-border: 1px solid rgba(229, 167, 88, .32);
	
	--fill-btn-color: #fff;
	// --header-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
	--theme-color: #3CC;
  --classic-color: #fff;
	--button-outline-shadow-hover: 0px 0px 12px rgba(51, 204, 204, 0.65), inset 0px 0px 16px #3CC, inset 0px -6px 5px rgba(0, 0, 0, 0.25);
	--button-filled-shadow-hover: 0px 0px 12px rgba(51, 204, 204, 0.65);
	--header-outline-btn-bg: #131922;
  --header-search-bg: #131922;
  --gslider-title-background: linear-gradient(90deg, #0A101A 0%, rgba(0, 0, 0, 0.00) 52.18%);
	--casino-win-bg: #1D282D;
  --input-background: #131922;
	--input-border-color: rgba(255, 255, 255, .17);
  --login-input-active-autofill: 0 0 0px 500px #131922 inset;
	--casino-category-img: invert(61%) sepia(100%) saturate(278%) hue-rotate(131deg) brightness(116%) contrast(103%);
	--cabinet-nav-bg: #1D282D;
	--cabinet-form-bg: #131D22;
	--casino-nav-img: invert(61%) sepia(100%) saturate(278%) hue-rotate(131deg) brightness(116%) contrast(103%);
  --navbar-search-border: #1D282D;
	--green-text: #7CF102;
	--table-border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	--banner-bg-slide-m: rgba(0, 0, 0, .7);
  --side-menu-selected: rgba(255, 255, 255, 0.08);
  --footer-text-clr: #67696f;
}
