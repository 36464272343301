.casino-page {

	// &:not(.games) {
	// 	.casino {
	// 		&-header-container {
	// 			position: relative;
	// 			display: grid;
	// 			grid-template-columns: 1fr 400px;
	// 			grid-gap: 20px;
	// 		}
	// 	}
	// }
}


.casino {
	&-header {
		// margin-block-end: 50px;
		// margin-block-start: 20px;
		// .banner {
		// 	margin: 0;
		// }
		// .banner-slide-img {
		// 	min-height: 250px;
		// 	object-position: right;
		// }
	}
	&-win {
		background: var(--casino-win-bg);
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
		border-radius: 7px;
		height: 100%;
		padding: 25px;
		transition: height .2s;
		overflow: hidden;
		display: grid;
		grid-template-rows: auto 1fr;
		&.opened {
			height: 100%;
		}
		&-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			cursor: initial !important;
		}
		&-arrow {
			display: flex;
			align-items: center;
			justify-content: center;
			transition: .2s;
			transform: rotate(0deg);
			display: none;
			&.opened {
				transform: rotate(180deg);
			}
		}
		&-block {
			position: relative;
			margin-block-start: 7px;
		}
		&-heading {
			font-size: 18px;
			color: var(--classic-color);
			font-weight: 500;
			margin: 0;
		}
		&-scroll {
			overflow-y: auto;
			position: absolute;
			top: 0;
			inset-inline-start: 0;
			width: 100%;
			height: 100%;
			padding-inline-end: 10px;
		}
		&-data {
			display: grid;
			grid-template-columns: 2fr 1fr;
			grid-gap: 10px;
			align-items: center;
			padding: 10px 0;
			&:not(:last-child) {
				border-bottom: 1px solid #717171;
			}
		}
		&-info {
			display: flex;
			align-items: center;
			img {
				flex-shrink: 0;
				flex-grow: 0;
				margin-inline-end: 10px;
				object-fit: contain;
				width: 56px;
				height: 33px;
			}
			p {
				color: #717171;
				font-size: 14px;
				margin: 0;
			}
		}
		&-value {
			text-align: right;
			font-size: 14px;
		}
		&-id {
			color: #DB3F33;
			margin: 0;
		}
		&-currency {
			color: #717171;
			margin: 0;
		}
	}
	&-content {

	}
	&-nav {
		padding: 5px 20px;
		display: grid;
		grid-template-columns: 1fr 300px;
		grid-gap: 50px;
		margin-block-end: 40px;
		align-items: center;
		border-radius: 6px;
		background: linear-gradient(90deg, #0D1013 0%, rgba(13, 16, 19, 0.5) 100%);
		border-left: 8px solid #EEAF5D;
		&-menu {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
		}
	}
	&-navT {
		padding-bottom: 24px;
		padding-top: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: nowrap;
		overflow-x: auto;
		display: none;
		.sidebar-link {
			flex-direction: column;
			align-items: center;
			padding: 0;
			white-space: nowrap;
			&:not(:last-child) {
				margin-inline-end: 30px;
			}
			&:hover, &.active {
				.sidebar-link-icon:before {
					filter: drop-shadow(0px 0px 12px rgba(238, 175, 93, 0.68));
				}
			}
		}
		.sidebar-link-icon {
			margin-inline-end: 0;
			width: 24px;
			height: 24px;
			margin-bottom: 5px;
		}
		@media screen and (max-width: 700px) {
			padding-top: 20px;
		}
	}
	&-category {
		display: flex;
		align-items: center;
		margin: 10px 10px;
		&:not(:last-child) {
			margin-inline-end: 32px;
		}
		.svg-block {
			width: 40px;
			height: 40px;
			margin-inline-end: 10px;
			flex-shrink: 0;
			flex-grow: 0;
			svg {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
		p {
			font-size: 20px;
			color: var(--classic-color);
			margin: 0;
			text-align: left;
			transition: .15s;
		}
		svg path {
			transition: .15s;
		}
		&:hover, &.active {
			svg path {
				fill: #EEAF5D;
			}
			p {
				text-shadow: 0px 0px 5px rgba(238, 175, 93, 0.32);
				color: #EEAF5D;
			}

		}
	}
	&-providers {
		margin-block-start: 40px;
		padding-block-end: 10px;
		.swiper-slide {
			width: 120px;
		}
		.swiper-horizontal>.swiper-scrollbar, .swiper-scrollbar.swiper-scrollbar-horizontal {
			position: static;
			margin-block-start: 10px;
			height: 8px;
		}
		.swiper-scrollbar {
			background: var(--sidebar-bg);
			width: 100% !important;
			.swiper-scrollbar-drag{
				background-color: #EEAF5D;
			}
		}
		&-reset {
			color: #EEAF5D;
			font-weight: 600;
			font-size: 16px;
			text-decoration: underline;
			display: flex;
			align-items: center;
			margin-top: 6px;
		}
		&-btn {
			background: #171A1C;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 120px;
			margin: 0 auto;
			height: 90px;
			flex-shrink: 0;
			flex-grow: 0;
			border: 1px solid transparent;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			&.selected {
				border-color: #836338;
			}
			&:hover {
				border-color: rgba(255, 255, 255, .24);
			}
			img {
				width: 75%;
				height: 75%;
				object-fit: contain;
				&::selection {
					background: transparent;
				}
			}
			&:not(:last-child) {
				margin-inline-end: 30px;
			}
		}
		&-top {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-block-start: 50px;
		}
		&-title {
			font-size: 20px;
			color: var(--classic-color);
			margin: 0;
			padding-inline-end: 15px;
			font-weight: 700;
		}
		&-show {
			color: #8D9095;
			font-size: 16px;
			margin: 0;
			margin-inline-end: 12px;
		}
		&-switch {
			display: flex;
			align-items: center;
		}
	}
	&-games {
		margin-block-start: 50px;
		&-grid {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-gap: 30px;
			&::-webkit-scrollbar {
				width: 0px;
				background: transparent; /* make scrollbar transparent */
			}
		}
	}
	&-divider {
		width: 100%;
		height: 1px;
		display: none;
		position: relative;
		&.mb {
			margin-bottom: 24px;
		}
		&-left {
			background: linear-gradient(54deg, #EEAF5D 0%, rgba(2,0,36,0) 100%);
		}
		&-center {
			background: radial-gradient(circle, #EEAF5D 0%, rgba(0, 0, 0, 0) 100%);
		}
	}
}

.casinoGame {
	position: relative;
	height: 100%;
	&-img {
		width: 100%;
		max-height: 270px;
		height: 100%;
		animation: fromOpacity 1s;
		overflow: hidden;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			transform: scale(1);
			transition: all .2s;
		}
	}
	&-footer {
		opacity: 0;
		animation: fromOpacity 0.5s 0.5s forwards;
		position: absolute;
		bottom: 0;
		inset-inline-start: 0;
		width: 100%;
		background: rgba(0, 0, 0, 0.6);
		padding: 0 15px;
		transition: all .2s;
		height: 50px;
		overflow: hidden;
		&-row {
			display: grid;
			grid-template-columns: 1fr auto;
			grid-gap: 20px;
			align-items: center;
			height: 50px;
		}
		&-content {
			display: grid;
			grid-template-columns: auto 1fr;
			grid-gap: 10px;
			align-items: center;
		}
	}
	&-heart {
		font-size: 20px;
		cursor: pointer;
		path {
			fill: #fff;
		}
		&.favorite path {
			fill: red;
		}
	}
	&-name {
		font-size: 16px;
		color: #fff;
		max-width: 100%;
		width: 100%;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		margin: 0;
	}
	&-rating {
		display: flex;
		align-items: center;
		svg {
			margin-inline-end: 10px;
			path {
				fill: #EEAF5D;
			}
		}
		p {
			margin: 0;
			font-size: 16px;
			font-weight: 700;
			color: #fff;
		}
	}
	&-btn {
		display: flex;
		justify-content: center;
		opacity: 0;
		// button {
		// 	background: var(--theme-color);
		// 	border-radius: 31px;
		// 	padding: 8px 34px;
		// 	color: #fff;
		// 	font-size: 14px;
		// 	transition: .15;
		// 	&:hover {
		// 		box-shadow: var(--button-filled-shadow-hover);
		// 	}
		// }
	}
	&-info {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 50px;
		opacity: 0;
		font-size: 16px;
		&-btn {
			color: #fff;
			display: flex;
			align-items: center;
			svg {
				width: 17px;
				height: auto;
				margin-inline-end: 5px;
			}
			p {
				text-decoration: underline;
				font-weight: 700;
				margin: 0;
			}
		}
	}
	&-demo {
		color: #EEAF5D;
		text-decoration: underline;
		font-weight: 700;
	}
	&:hover {
		@media screen and (min-width: 992px) {
			.casinoGame-footer {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				height: 100%;
				background:rgba(0, 0, 0, 0.8);
			}
			.casinoGame-btn {
				opacity: 1;
				transition: all .2s;
				transition-delay: .1s;
			}
			.casinoGame-info {
				opacity: 1;
				transition: all .2s;
				transition-delay: .1s;
			}
			.casinoGame-img img {
				transform: scale(1.03);
			}
		}
	}
}


@keyframes fromOpacity {
	from {
		opacity: 0;
	} to {
		opacity: 1;
	}
}


.casinoInfoModal {
	.theme-btn.mb {
		margin-bottom: 5px;
	}
	&-row {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 30px;
	}
	&-img {
		margin-block-end: 20px;
		img {
			width: 100%;
			height: auto;
		}
	}
	&-demo {
		margin-block-start: 15px;
	}
	&-title {
		color: #fff;
		font-size: 20px;
		margin-block-end: 20px;
	}
	&-text {
		margin: 0;
		color: #fff;
		font-size: 16px;
		span {
			color: #EEAF5D;
		}
		&:not(:last-child) {
			margin-block-end: 10px;
		}
	}
}

@media screen and (max-width: 530px) {
	.casinoInfoModal-row {
		grid-template-columns: 1fr;
	}
	.casinoInfoModal-img {
		margin-block-end: 20px;
	}
}



// @media screen and (max-width: 1440px) {
// 	.casino-page:not(.games) .casino-header-container {
// 		grid-template-columns: 1fr 370px;
// 		grid-gap: 15px;
// 	}
// }

@media screen and (max-width: 1400px) {
	.casino-category p {
		font-size: 18px;
	}
}

@media screen and (max-width: 1320px) {
	.casino-navT {
		display: flex;
	}
	.casino-divider {
		display: block;
	}
	.casino-divider-left {
		inset-inline-start: -30px;
	}
	.content-py.navT-pd {
		padding-top: 0;
	}
	.casino-page {
		.banner {
			display: none;
		}
	}
	.casino-nav {
		border-left-width: 3px;
	}
	.casino-providers .swiper-horizontal > .swiper-scrollbar, .casino-providers .swiper-scrollbar.swiper-scrollbar-horizontal {
		height: 3px;
	}
}

@media screen and (max-width: 1130px) {
	.casino-games-grid {
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 20px;
	}
}

@media screen and (max-width: 1070px) {
	.casino-category p {
		font-size: 16px;
	}
	.casino-category img {
		width: 20px;
		height: 20px;
	}
	.casino-category {
		margin: 5px 10px;
	}
	.casino-category:not(:last-child) {
		margin-inline-end: 25px;
	}
}

@media screen and (max-width: 991px) {
	.casino-header {
		.banner {
			display: none;
		}
		.banner-px {
			padding: 0 30px;
		}
	}
	.casino-win {
		position: relative;
		width: 100%;
		margin-block-start: 15px;
		&-block {
			max-height: 300px;
			height: auto;
		}
		&.opened {
			height: 100%;
		}
		&-arrow {
			display: flex;
		}
		&-block {
			display: none;
			&.opened {
				display: block;
				margin-block-start: 20px;
			}
		}
	}
	// .casino-page:not(.games) .casino-header-container {
	// 	display: block;
	// 	padding-inline-end: 0;
	// }
	.casino-win-scroll {
		position: relative;
	}
	.casino-header {
		margin-block-end: 30px;
	}
	.casino-nav {
		margin-block-end: 30px;
		grid-template-columns: 1fr;
		grid-gap: 20px;
		padding: 20px;
	}
	.casino-providers {
		margin: 20px 0;
	}

	.casino-win-arrow {
		width: 20px;
		height: 20px;
		border: 1px solid var(--theme-color);
		border-radius: 50%;
		img {
			width: 60%;
			height: 60%;
		}
	}
	.casino-nav-search {
		order: 1;
	}
	.casino-nav-menu {
		order: 2;
	}
	.casino-providers-top {
		display: block;
	}
	.casino-providers-title {
		margin-block-end: 18px;
	}
}

@media screen and (max-width: 920px) {
	.casino-nav {
		grid-template-columns: 1fr;
		grid-gap: 30px;
	}
	.casino-nav {
		padding: 20px 20px;
	}
}

@media screen and (max-width: 700px) {
	.casino-header .banner-px {
		padding: 0 15px;
	}
	.casino-providers-title {
		font-size: 18px;
	}
	.casino-games-grid {
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
		grid-gap: 20px;
	}
	.casino-divider {
		inset-inline-start: -15px;
	}
}

@media screen and (max-width: 680px) {
	.casino-providers-btn {
		width: 100px;
		height: 64px;
	}
	.casino-providers .swiper-slide {
		width: 100px;
	}
	.casino-providers-btn:not(:last-child) {
		margin-inline-end: 15px;
	}
}

@media screen and (max-width: 610px) {
	.casino-navT {
		justify-content: space-between;
	}
	.casino-win-info img {
		margin-inline-end: 4px;
	}
	.casino-win-info p {
		font-size: 13px;
	}
	.casino-win-value {
		font-size: 13px;
	}
}

@media screen and (max-width: 530px) {
	.casinoGame-footer {
		padding: 0 10px;
	}
	.casino-category p {
		font-size: 14px;
	}
	.casino-category .svg-block {
		width: 32px;
		height: 32px;
	}
	.casino-nav {
		padding: 20px 15px;
		margin-bottom: 20px;
	}
}

@media screen and (max-width: 500px) {
	.casino-providers-top {
		display: block;
	}
	.casino-providers-title {
		padding-inline-end: 0;
		margin-block-end: 15px;
	}
}

@media screen and (max-width: 470px) {
	.casino-category:not(:last-child) {
		margin-inline-end: 10px;
	}
	.casino-nav {
		padding: 20px 15px;
	}
	.casino-win {
		padding: 20px 15px;
	}
	.casino-win-header {
		height: 37px;
	}
	.casinoGame-footer-row {
		display: block;
		height: auto;
	}
	.casinoGame-footer {
		position: relative;
	}
	.casinoGame-btn,
	.casinoGame-info {
		display: none;
	}
	.casinoGame-heart {
		font-size: 17px;
	}
	.casinoGame-img {
		height: auto;
	}
	.casinoGame-footer {
		padding: 10px;
		height: auto;
	}
	.casinoGame {
		display: grid;
		grid-template-rows: 1fr auto;
	}
	.casino-games-grid {
		grid-template-rows: initial;
		grid-gap: 10px;
	}
	.casinoGame-footer-content {
		grid-gap: 6px;
	}
	.casinoGame-rating {
		margin-block-start: 4px;
	}
	.casinoGame-rating .anticon {
		margin-inline-end: 6px;
	}
	.casinoGame-name, .casinoGame-rating p {
		font-size: 12px;
	}
}
