.banner {
  max-width: 100%;
  position: relative;
  margin-bottom: 18px;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  &.content-px {
    padding: 0;
  }
  .swiper {
    padding: 30px;
  }
  .swiper-wrapper {
    border-radius: 12px;
    border: var(--card-border);
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.2);
  }
  &-slide {
    position: relative;
    height: 25vw;
    transition: 0.15s;
    overflow: hidden;
    border-radius: 12px;
    &-link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 3;
    }
    cursor: pointer;
    .liveCasino-play {
      z-index: 4;
    }
    &:hover {
      border-color: #e5a758;
      box-shadow: 0px 0px 24px 0px rgba(240, 178, 94, 0.32);
      .liveCasino-play {
        opacity: 1;
      }
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      background: linear-gradient(90deg, #0d1013 0%, rgba(13, 16, 19, 0.32) 60.94%, rgba(13, 16, 19, 0) 100%);
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 12px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      &.right-position {
        background-position: right;
      }
      // background-image: url('/images/junoclient/palace.webp');
    }
  }
  &-logo {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    margin-inline-start: 3vw;
    z-index: 2;
    width: 22vw;
    height: auto;
    &.desktop {
      @media screen and (max-width: 700px) {
        display: none;
      }
    }
    &.mobile {
      @media screen and (min-width: 701px) {
        display: none;
      }
    }
  }
}

.homepage.content-py {
  padding-top: 0;
}

.carousel.carousel-slider .control-arrow:hover {
  background: none !important;
}
.swiper-pagination {
  position: absolute;
  z-index: 2;
  bottom: 50px;
  background: rgba(0, 0, 0, 0.5);
  margin: 0 auto;
  padding: 4px;
  border-radius: 10px;
  padding: 5px 7px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.swiper-pagination-bullet {
  cursor: pointer;
  transition: opacity 0.25s ease-in;
  background: #fff;
  opacity: 0.3;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  display: block;
  &.swiper-pagination-bullet-active {
    opacity: 1;
  }
  &:not(:last-child) {
    margin-inline-end: 6px;
  }
}

@media screen and (max-width: 700px) {
  .banner .swiper-wrapper {
    border: 0;
    border-radius: 0;
    box-shadow: none;
  }
  .banner-slide {
    height: 320px;
    border-radius: 0;
    &:before {
      border-radius: 0;
    }
  }
  .banner-logo {
    width: 195px;
    margin: auto;
    right: 0;
    left: 0;
  }
  .banner {
    .swiper {
      padding: 0;
    }
    video {
      border-radius: 0;
    }
  }
}
