.sidebarM {
	transform: translate3d(100%, 0, 0);
	max-inline-size: 350px;
	width: 100%;
	position: fixed;
	top: 0;
	bottom: 0;
	overflow-y: auto;
	height: 100%;
	z-index: 11;
	inset-inline-end: 0;
	transition: transform 0.2s ease-in-out;
	backface-visibility: hidden;
	background-image: none;
	background-color: #131517;
	border-left: 1px solid rgba(255, 255, 255, .08);


	// background-size: 70px 70px;
	// background-repeat: repeat;
	// &:before {
	// 	content: "";
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	width: 100%;
	// 	height: 100%;
	// 	background-color: rgba(13, 16, 19, 0.90);
	// }
	&:not(.sidebarM-main) {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	&.active {
		transform: translate3d(0, 0, 0);
	}
	&-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-block-end: 32px;
		padding: 10px 24px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.12);
		background: #0D1013;
		box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.24);
		height: 60px;
		position: relative;
		&-logo {
			.svg-block {
				width: 36px;
				height: 36px;
				svg {
					width: 100%;
					height: 100%;
				}
			}
		}
		// &-switch {
		// 	display: flex;
		// 	align-items: center;
		// 	.navbar-theme {
		// 		margin-inline-end: 20px;
		// 	}
		// }
	}
	&-close {
		display: flex;
		cursor: pointer;
		.svg-block {
			width: 28px;
			height: 28px;
			svg {
				width: 100%;
				height: 100%;
			}
		}
	}
	&-user {
		display: grid;
		grid-template-columns: auto 1fr;
		grid-gap: 12px;
		align-items: center;
		padding-bottom: 24px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.12);
		position: relative;
		&-img {
			width: 56px;
			height: 56px;
		}
		&-email {
			font-size: 16px;
			color: #fff;
			font-weight: 500;
			margin-bottom: 0;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
		&-balance {
			color: #F0B25E;
			font-size: 20px;
			font-weight: 600;
			margin-bottom: 0;
		}
	}
	&-content {
		padding: 0 24px;
		position: relative;
	}
	&-links {
		position: relative;
		margin-top: 16px;
	}
	&-link {
		display: flex;
		align-items: center;
		color: var(--sidebar-link-color);
		font-size: 16px;
		font-weight: 500;
		text-decoration: none;
		margin-block-end: 20px;
		&:not(.sidebarM-logout) {
			justify-content: space-between;
		}
		&.active, &:hover {
			color: #F0B25E;
			svg path {
				fill: #F0B25E !important;
			}
		}
		&-row {
			display: flex;
			align-items: center;
			.svg-block {
				width: 30px;
				height: 30px;
				margin-inline-end: 12px;
				svg {
					width: 100%;
					height: 100%;
					object-fit: contain;
					path {
						fill: #B7B7B7;
					}
				}
			}
		}
		&-main {
			.svg-block {
				width: 19px;
				height: 19px;
			}
		}
	}
	&-logout {}
	&-main-bottom {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 16px;
		align-items: center;
	}
	&-support {
		color: #fff;
		font-size: 16px;
		display: flex;
		align-items: center;
		transition: .15s;
		&-icon {
			margin-inline-end: 12px;
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			border-radius: 50%;
			border: 1px solid rgba(255, 255, 255, 0.08);
			background: linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 100%), #0D1013;
			box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.16), 0px 6px 8px 0px rgba(255, 255, 255, 0.06) inset;
		}
		&.active, &:hover {
			color: #F0B25E;
		}
	}
	&-divider {
		background: rgba(183, 183, 183, 0.5);
		width: 100%;
		height: 1px;
		margin: 30px 0 100px;
	}
	&-search {
		margin-block-end: 30px;
		&-games {
			max-height: 500px;
			height: 100%;
			overflow-y: auto;
			margin-bottom: 20px;
			.gameCard {
				display: flex;
				align-items: center;
				width: 100%;
				&:not(:last-child) {
					margin-block-end: 5px;
					padding-block-end: 5px;
					border-bottom: 1px solid rgba(183, 183, 183, 0.2);
				}
				&-img {
					width: 50px;
					height: 50px;
					margin-inline-end: 15px;
				}
				&-name {
					margin: 0;
					text-align: left;
				}
				.glow {
					display: none;
				}
			}
		}
		&-divider {
			margin: 30px 0;
			width: 100%;
			height: 1px;
			opacity: .5;
			background: var(--classic-color);
		}
	}
	&-back {
		display: inline-flex;
		align-items: center;
		font-size: 14px;
		color: var(--sidebar-link-color);
		margin-block-end: 20px;
		img {
			margin-inline-end: 10px;
			width: 6px;
			height: auto;
		}
	}
	&-lang {
		display: flex;
		align-items: center;
		justify-content: space-between;
		p {
			color: var(--sidebar-link-color);
			font-size: 16px;
			margin: 0;
		}
	}
	.light-theme-icon {
		@media screen and (max-width: 1320px) {
			filter: brightness(0);
			opacity: .5;
		}
	}
	&-profile {
		&-name {
			color: #fff;
			margin-block-end: 7px;
		}
		&-balance {
			color: var(--theme-color);
		}
	}
}

.sidebarM-main {
	transform: translate3d(-100%, 0, 0);
	inset-inline-end: initial;
	inset-inline-start: 0;
	display: grid;
	&-divider {
		background: rgba(183, 183, 183, 0.5);
		width: 100%;
		height: 1px;
		margin: 23px 0;
	}
}

@media screen and (max-width: 700px) {
	.sidebarM {
		max-width: 100%;
	}
	.sidebarM-content {
		padding: 0 20px;
	}
	.sidebarM-header {
		padding: 10px 20px;
	}
}

@media screen and (max-width: 380px) {
	.sidebarM-support {
		font-size: 14px;
	}
}
