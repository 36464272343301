.legal {
	border-radius: 12px;
	background: linear-gradient(0deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%), #0D1013;
	overflow: hidden;
	border: var(--card-border);
	&__title {
		background: #0D1013;
		padding: 30px;
		font-size: 24px;
		color: #fff;
		font-weight: 700;
	}
	&__content {
		padding: 30px;
		color: rgba(255, 255, 255, 0.80);
		font-size: 16px;
		line-height: 130%;
	}
	h2 {
		font-size: 20px;
		text-transform: uppercase;
		color: #fff;
		font-weight: 700;
		margin-bottom: 32px;
	}
	p {
		margin-bottom: 0;
		span {
			font-size: 18px;
			color: #fff;
			font-weight: 600;
		}
		&.mb {
			margin-bottom: 24px;
		}
		&.mt {
			margin-top: 32px;
		}
		&.white-color {
			color: #fff;
			text-transform: uppercase;
			font-weight: 600;
		}
	}
	&__table {
		width: 100%;
		border-collapse: collapse;
		border-radius: 8px;
		border: 1px solid rgba(255, 255, 255, 0.24);
		overflow: hidden;
		&-container {
			border: 1px solid rgba(255, 255, 255, 0.24);
			border-radius: 8px;
			margin: 32px 0;
			overflow-x: auto;
		}
		&-grid {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 32px;
			align-items: flex-start;
			.legal__table-container {
				margin: 0;
				margin: 32px 0;
			}
		}
		th, td {
			padding: 8px;
			text-align: left;
			border: 1px solid rgba(255, 255, 255, 0.24);;
		}
		th {
			padding: 24px;
			background: rgba(255, 255, 255, 0.04);
			font-size: 18px;
			color: #fff;
			font-weight: 600;
		}
		td {
			padding: 16px 24px;
			&.td-min-width {
				min-width: 230px;
			}
		}
	}
}

@media screen and (max-width: 1120px) {
	.legal__table-grid {
		grid-template-columns: 1fr;
		grid-gap: 0;
		.legal__table-container:first-child {
			margin-bottom: 0;
		}
 	}
	
}

@media screen and (max-width: 600px) {
	.legal__content {
		padding: 20px 15px;
	}
	.legal__title {
		padding: 20px 15px;
	}
	.legal__table th {
		font-size: 16px;
	}
	.legal__table td {
		font-size: 14px;
	}
}
