.playNowModal {
	.custom-alert {
		border: 0;
		border-radius: 0;
	}
  &-header {
		padding: 30px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.12);
		background: #0D1013;
		display: grid;
		grid-template-columns: 1fr auto;
		grid-gap: 15px;
	}
	&-balance {
		color: #fff;
		font-size: 14px;
		letter-spacing: 0.5px;
	}
  &-actions {
   	display: flex;
  }
  &-resize {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
		margin-inline-end: 10px;
    img {
      width: 60%;
      height: 60%;
    }
  }
  &-body {
    position: relative;
    min-height: 500px;
    @media screen and (max-height: 700px) {
      min-height: auto;
    }
  }
  &-iframe {
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 100%;
    &.overflow-auto {
      overflow: auto;
    }
    iframe {
      height: 100%;
    }
  }
  &-grid {
    position: relative;
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
    overflow: hidden;
  }
  &-rating {
    &-value {
      color: #fff;
      font-size: 16px;
      margin: 0;
      margin-inline-start: 5px;
    }
  }
  &-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-text {
    font-size: 16px;
    color: var(--classic-color);
    margin-block-start: 40px;
  }
	.theme-modal-close {
		padding: 0;
		svg {
			width: 80%;
		}
	}
}

@media screen and (max-width: 1320px) {
  .playNowModal-resize {
    display: none;
  }
}

@media screen and (max-width: 991px) {
	.playNowModal-header-left {
		.theme-modal-title,
		.playNowModal-rating {
			display: none;
		}
	}
	.playNowModal-header {
		padding: 10px;
		align-items: center;
		// display: flex;
		// justify-content: flex-end;
		// grid-gap: 0;
	}
}