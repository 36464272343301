.langSwitch {
	&__btn {
		display: flex;
		align-items: center;
		width: 100%;
		padding: 12px 16px;
		border-top: var(--sidebar-block-border);
		position: relative;
		transition: .2s;
		color: var(--sidebar-link-color);
    	font-size: 14px;
    	transition: 0.2s;
		&:hover, &.active {
			color: #EEAF5D;
		}
		.svg-block {
			width: auto;
		}
		svg {
			position: absolute;
			top: 0;
			bottom: 0;
			width: 7px;
			height: auto;
			right: 16px;
			margin: auto 0;
		}
		&-icon {
			width: 19px;
			height: 19px;
			margin-inline-end: 12px;
			flex-shrink: 0;
			flex-grow: 0;
			display: flex;
			align-items: center;
			border-radius: 50%;
			justify-content: center;
			img {
				width: 100%;
				height: 100%;
				border-radius: 50%;
			}
		}

	}
	&__menu {

	}
}

.custom-mui-select {
	.MuiList-root {
		padding: 12px 24px !important;
	}
	.MuiTouchRipple-root {
		display: none;
	}
	.MuiMenuItem-root {
		padding: 12px 0;
		border-bottom: 1px solid rgba(255, 255, 255, 0.12);
		color: #B7B7B7;
		font-size: 14px;
		letter-spacing: 0.037px;
		display: flex;
		align-items: center;
		font-family: "Inter", sans-serif;
		min-width: 140px;
		transition: .15s;
		img {
			width: 24px;
			height: 24px;
			border-radius: 50%;
			object-fit: cover;
			margin-inline-end: 12px;
			flex-shrink: 0;
			flex-grow: 0;
		}
		&:last-child {
			border-bottom: 0;
		}
		&.Mui-selected, &:hover, &.Mui-selected:hover {
			color: #EEAF5D;
			background: transparent;
		}
	}
	.MuiPaper-root {
		@media screen and (min-width: 1321px) {
			left: 256px !important;
		}
		border-radius: 6px;
		border: 1px solid rgba(255, 255, 255, 0.12);
		background: linear-gradient(0deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%), #0D1013;
		box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
	}
}

@media screen and (max-width: 1320px) {
	.langSwitch__btn {
		padding: 0;
		border-top: 0;
		color: #fff;
		font-size: 16px;
		.svg-block {
			display: none;
		}
	}

	.langSwitch__btn-icon {
		margin-inline-end: 12px;
		width: 40px;
		height: 40px;
		padding: 8px;
		border: 1px solid rgba(255, 255, 255, 0.08);
		background: linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 100%), #0D1013;
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.16), 0px 6px 8px 0px rgba(255, 255, 255, 0.06) inset;
	}
}

@media screen and (max-width: 380px) {
	.langSwitch__btn {
		font-size: 14px;
	}
}
