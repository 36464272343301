// .MuiSwitch-colorPrimary.Mui-checked {
// 	color: #EEAF5D !important;
// }
.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
	background-color: #EEAF5D !important;
}
// .MuiSwitch-switchBase.Mui-checked:hover {
// 	background-color: rgba(238, 175, 93, .1) !important;
// }

