.mb-10 {
  margin-block-end: 10px;
}

.block-fill {
  background: var(--sidebar-block-bg);
  border-radius: var(--sidebar-block-border-radius);
  border: var(--sidebar-block-border);
  &.no-pd {
    padding: 0;
  }
}

.sidebar {
  position: sticky;
  height: 100vh;
  top: 0;
  inset-inline-start: 0;
  z-index: 10;
  width: 256px;
  background: var(--sidebar-bg);
  border-right: 1px solid rgba(255, 255, 255, 0.08);
  box-shadow: -12px 4px 24px rgba(0, 0, 0, 0.16);
  // overflow: auto;
  &-container {
    padding: 20px 15px;
    &-time {
      padding: 0 15px 20px;
    }
  }
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
  &-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &-block {
    &.block-bottom {
      margin-top: 16px;
    }
  }
  &-logo {
    width: 100%;
    height: auto;
    margin-block-end: 30px;
    display: flex;
    img {
      width: 100%;
      height: auto;
    }
  }
  &-profile {
    margin-block-end: 30px;
    &-img {
      display: flex;
      align-items: center;
      margin: -30px auto 14px;
      width: 60px;
      height: 60px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    svg {
      width: 60px;
      height: 60px;
      object-fit: contain;
    }
    &-username {
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 16px;
      color: var(--classic-color);
    }
    &-balance {
      padding: 16px;
      color: #deba70;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      border-top: 1px solid rgba(255, 255, 255, 0.12);
      background: rgba(255, 255, 255, 0.04);
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1) inset;
      border-bottom-left-radius: var(--sidebar-block-border-radius);
      border-bottom-right-radius: var(--sidebar-block-border-radius);
      word-wrap: break-word;
    }
  }
  &-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 12px 16px;
    &-btn {
      &:not(:last-child) {
        border-bottom: var(--sidebar-block-border);
      }
    }
    .svg-block {
      position: relative;
    }
    &.sidebar-link-promotions {
      margin-block-end: 15px;
    }
    &-icon {
      margin-inline-end: 15px;
      position: relative;
      width: 17px;
      height: 17px;
      svg {
        height: 100%;
        width: 100%;
        object-fit: contain;
        path {
          transition: 0.15s;
        }
      }
      &:before {
        content: "";
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        width: 140%;
        height: 140%;
        inset-inline-start: -20%;
        top: -20%;
        transition: 0.15s;
        background: linear-gradient(115deg, #be8d46 16.64%, #97672e 37.09%, #c99149 48.04%, #f0b25e 57.53%, #ffbe66 62.64%, #eeaf5d 68.48%, #c08944 80.17%, #97672e 89.66%);
        filter: drop-shadow(0px 0px 7px #f0b25e);
        border-radius: 50%;
        opacity: 0;
      }
    }
    span {
      color: var(--sidebar-link-color);
      font-size: 14px;
      transition: 0.15s;
    }
    &:hover {
      svg {
        path {
          &.stroke-hover {
            stroke: #eeaf5d;
          }
          &:not(.stroke-hover) {
            fill: #eeaf5d;
          }
        }
      }
      span {
        color: #eeaf5d;
      }
    }
    &.active {
      .sidebar-link-icon:not(.support-icon) {
        svg path {
          fill: #000;
        }
        &:before {
          opacity: 1;
        }
      }
      .sidebar-link-icon.support-icon {
        svg path {
          &.stroke-hover {
            stroke: #eeaf5d;
          }
        }
      }
      span {
        background: linear-gradient(115deg, #be8d46 16.64%, #97672e 37.09%, #c99149 48.04%, #f0b25e 57.53%, #ffbe66 62.64%, #eeaf5d 68.48%, #c08944 80.17%, #97672e 89.66%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  &-time {
    display: flex;
    align-items: center;
    margin-block-start: 30px;
    .svg-block {
      margin-inline-end: 10px;
      width: auto;
    }
    p {
      color: var(--sidebar-link-color);
      font-size: 16px;
      font-weight: 500;
      margin-block-end: 0;
    }
  }
}

@media screen and (max-width: 1580px) {
  .sidebar-time {
    p {
      font-size: 14px;
    }
  }
}
