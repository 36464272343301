.error404 {
	border-radius: 8px;
	border: 1px solid #BE8D46;
	background: linear-gradient(0deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%), #0D1013;
	box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
	padding: 90px 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	min-height: 480px;
	.svg-block {
		position: absolute;
		left: 0;
		top: 0;
		width: 60%;
		height: 60%;
		right: 0;
		bottom: 0;
		margin: auto;
		svg {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
	&__content {
		max-width: 500px;
		width: 100%;
		text-align: center;
		position: relative;
	}
	&__title {
		color: #FFF;
		font-size: 48px;
		letter-spacing: 0.132px;
		margin-bottom: 15px;
		line-height: 1;
		font-weight: 800;
	}
	&__text {
		color: rgba(255, 255, 255, 0.74);
		font-size: 18px;
		margin-bottom: 24px;
	}
}

.error404-content-py {
	
}

.errorSession {
	flex-direction: column;
	.svg-block {
		position: static;
		max-width: 250px;
		height: auto;
		margin-bottom: 32px;
	}
}

@media screen and (max-width: 1320px) {
	.error404-content-py {
		padding-top: 0;
	}
	.error404 {
		min-height: initial;
		padding: 30px 20px;
	}
}

@media screen and (max-width: 991px) {
	.error404__title {
		font-size: 34px;
	}
	.error404__text {
		font-size: 16px;
	}
}

@media screen and (max-width: 520px) {
	.error404__title {
		font-size: 25px;
	}
}