.theme-modal {
	&.full-screen {
		.MuiPaper-root {
			border-radius: 0;
		}
	}
	.MuiPaper-root {
		border-radius: 12px;
		overflow: hidden;
		background: #171A1C;
		position: relative;
		min-width: 700px;
		margin: 15px;
		@media screen and (max-width: 800px) {
			min-width: initial;
			width: 100%;
		}
		&.MuiDialog-paperFullScreen {
			margin: 0;
		}
	}
	&-heading {
		display: grid;
		grid-template-columns: 1fr auto;
		grid-gap: 15px;
		align-items: center;
		margin-bottom: 30px;
	}
	&-title {
		margin-bottom: 0;
		font-size: 20px;
		color: #fff;
		font-weight: 600;
	}
	&-close {
		width: 35px;
		height: 35px;
		border-radius: 8px;
		border: 1px solid rgba(255, 255, 255, 0.08);
		display: flex;
		align-items: center;
		justify-content: center;
		transition: .15s;
		flex-shrink: 0;
		flex-grow: 0;
		padding: 8px;
		&:hover {
			background: rgba(255, 255, 255, 0.04);
			border-color: rgba(255, 255, 255, 0.24);
		}
		&.absolute {
			position: absolute;
			top: 10px;
			inset-inline-end: 10px;
		}
		svg {
			width: 80%;
			height: auto;
			path {
				fill: #fff;
			}
		}
	}
  &-body {
		position: relative;
		padding: 30px;
  }
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
	&-iframe {
		margin-block-start: 20px;
	}
	&:not(.default-close) {
		.ant-modal-close {
			display: none;
		}
	}	
}

.addAccountModal {
	&-grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 20px 15px;
		@media screen and (max-width: 500px) {
			grid-template-columns: 1fr;
		}
	}
	&-btn {
		margin-block-start: 30px;
	}
	.ant-form-item {
		margin-block-end: 0 !important;
	}
}


@media screen and (max-width: 500px) {
  .theme-modal-body {
    padding: 20px;
  }
  .theme-modal-heading {
    font-size: 20px;
  }
}

// //--reset
// .ant-modal-content {
//   background: transparent;
// }
// .ant-modal-body {
//   padding: 0;
// }
// .ant-modal {
//   padding: 0;
// }

// @media screen and (max-width: 800px) {
// }
