.casino-scroll::-webkit-scrollbar,
.game-body::-webkit-scrollbar {
  width: 5px;
}

.casino-scroll-horizontal::-webkit-scrollbar-thumb:horizontal{
	background: #161616;
	border-radius: 20px;
}

.casino-scroll-horizontal::-webkit-scrollbar {
	height: 4px;
}

.casino-scroll-horizontal::-webkit-scrollbar-track {
	border-radius: 3px;
  background-color: var(--navbar-search-border);
}

.casino-scroll::-webkit-scrollbar-track,
.game-body::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: #5c5200;
}

.casino-scroll::-webkit-scrollbar-thumb,
.game-body::-webkit-scrollbar-thumb {
  background-color: #fcdf01;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
}