.main-navbar {
  position: sticky;
  touch-action: none;
  background: var(--header-bg);
  border-bottom: var(--sidebar-block-border);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.24);
  width: 100%;
  height: 70px;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-right {
    display: flex;
    align-items: center;
  }
  &-deposit {
    margin-right: 20px;
  }
}

.navbar-search {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 335px;
  &-icon {
    position: absolute;
    top: 0;
    inset-inline-start: 18px;
    bottom: 0;
    margin: auto 0;
    z-index: 1;
  }
  &:hover {
    input {
      border-color: rgba(255, 255, 255, 0.4);
    }
  }
  input {
    display: flex;
    border-radius: 38px;
    border: 1px solid rgba(255, 255, 255, 0.16);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 100%), #0d1013;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.24) inset;
    height: 45px;
    padding-inline-end: 13px;
    padding-inline-start: 50px;
    width: 100%;
    cursor: pointer;
    color: var(--classic-color);
    font-size: 14px;
    transition: 0.15s;
    &::placeholder {
      color: var(--sidebar-link-color);
    }
    &:focus {
      border-color: rgba(255, 255, 255, 0.4);
    }
  }

  &-res {
    position: absolute;
    width: 650px;
    padding-block-start: 30px;
    padding-block-end: 30px;
    padding-inline-start: 40px;
    padding-inline-end: 40px;
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%), #0d1013;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    inset-inline-start: 0;
    top: calc(100% + 15px);
    text-align: left;
    cursor: initial;
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent; /* make scrollbar transparent */
    }
  }
  &-title {
    margin-block-end: 30px;
    color: var(--classic-color);
    font-size: 20px;
  }
  &-games {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
    &.no-grid {
      display: block;
    }
    .gameCard {
      display: grid;
      grid-template-rows: 90px 1fr;
      align-items: center;
    }
    .gameCard-name {
      margin: 15px 0;
    }
    .gameCard-img {
      height: 100%;
      img {
        object-fit: cover;
      }
    }
  }
  &-game {
    &_img {
      display: flex;
      align-items: center;
    }
    &_name {
      color: var(--classic-color);
      font-size: 10px;
      text-align: center;
      margin-block-start: 6px;
      line-height: 130%;
    }
  }
  &-link {
    display: flex;
    align-items: center;
    color: var(--classic-color);
    font-size: 20px;
    text-decoration: none;
    transition: 0.15s;
    padding: 5px;
    opacity: 0.7;
    img {
      margin-inline-end: 12px;
    }
    &:hover {
      opacity: 1;
      color: var(--classic-color);
    }
  }
  .divider {
    height: 2px;
    width: 100%;
    background: linear-gradient(to bottom right, #eeaf5d 0%, rgba(238, 175, 93, 0) 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, #eeaf5d 0%, rgba(238, 175, 93, 0) 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, #eeaf5d 0%, rgba(238, 175, 93, 0) 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, #eeaf5d 0%, rgba(238, 175, 93, 0) 50%) top right / 50% 50% no-repeat;
    margin: 34px 0;
  }
  &-left {
    display: none;
    align-items: center;
  }
}

.navbar-logo {
  &-t {
    height: 40px;
    width: auto;
    display: flex;
    align-items: center;
    img {
      width: auto;
      height: 100%;
      object-fit: contain;
    }
  }
}

.profile-icon {
  border-radius: 50%;
  background: linear-gradient(115deg, #be8d46 16.64%, #97672e 37.09%, #c99149 48.04%, #f0b25e 57.53%, #ffbe66 62.64%, #eeaf5d 68.48%, #c08944 80.17%, #97672e 89.66%);
}

.navbar-profile {
  position: relative;
  &-btn {
    display: flex;
    align-items: center;
    width: 44px;
    height: 44px;
    //border-radius: 11px;
    //border: 1px solid #BE8D46;
    text-transform: uppercase;
    justify-content: center;
    color: #fff;
    text-align: center;
    text-shadow: 0px 6px 40px #fff;
    font-size: 20px;
    font-weight: 600;
    svg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-link {
    display: flex;
    align-items: center;
    padding: 12px 0;
    color: var(--sidebar-link-color);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.037px;
    width: 160px;
    transition: 0.15s;
    svg path {
      fill: #b7b7b7;
    }
    &:hover,
    &.active {
      color: #eeaf5d;
      svg path {
        fill: #eeaf5d;
      }
    }
    &:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    }
    svg {
      margin-inline-end: 5px;
      width: 25px;
      height: 25px;
      object-fit: contain;
      path {
        transition: 0.15s;
      }
    }
    .svg-block {
      width: auto;
    }
  }
  &-menu {
    padding: 12px 24px;
    z-index: 11;
    opacity: 0;
    position: absolute;
    top: calc(100% + 17px);
    height: auto;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%), #0d1013;
    display: none;
    &.active {
      opacity: 1;
      display: block;
    }

    &-left {
      left: 0;
    }

    &-right {
      right: 0;
    }
  }
  &-t {
    // margin-inline-end: 20px;
    display: flex;
    align-items: center;
    &-user {
      margin-inline-end: 16px;
      text-align: right;
    }
    &-name {
      font-size: 14px;
      line-height: 1;
      color: #fff;
      font-weight: 600;
    }
    &-balance {
      font-size: 16px;
      font-weight: 700;
      color: #fff;
      margin-inline-end: 16px;
      line-height: 1;
    }
  }
}

.navbar-mobileBtn {
  display: none;
  svg {
    width: 24px;
    height: 24px;
    margin-inline-end: 20px;
  }
}

@media screen and (max-width: 1320px) {
  .main-navbar {
    height: 60px;
    width: 100%;
  }
  .navbar-logo-t {
    display: flex;
  }
  .main-navbar-left {
    display: flex;
    align-items: center;
  }
  .navbar-mobileBtn {
    display: flex;
    align-items: center;
  }
  .navbar-profile {
    display: none;
  }
  .navbar-search-link {
    font-size: 14px;
  }
  .navbar-search-link img {
    width: 20px;
    height: auto;
  }
  .navbar-search-title {
    font-size: 14px;
    margin-block-end: 15px;
  }
  .navbar-profile-btn {
    width: 35px;
    height: 35px;
    border-radius: 8px;
    font-size: 16px;
  }
  .navbar-search {
    width: 100%;
  }
  .main-navbar-deposit {
    margin-right: 16px;
  }
}

@media screen and (max-width: 700px) {
  .navbar-mobileBtn div {
    height: 3px;
    width: 21px;
  }
  .navbar-mobileBtn div:not(:last-child) {
    margin-block-end: 3px;
  }
  .main-navbar-left {
    align-items: center;
  }
  .main-navbar {
    height: 50px;
  }
  .navbar-profile-btn {
    width: 32px;
    height: 32px;
    font-size: 14px;
  }
  .navbar-profile-t-balance {
    font-size: 14px;
    margin-inline-end: 12px;
  }
  .main-navbar .main-navbar-deposit {
    font-size: 14px;
    padding-top: 0;
    padding-bottom: 0;
    height: 38px;
  }
}

@media screen and (max-width: 425px) {
  .main-navbar .main-navbar-deposit {
    padding: 0 15px;
    font-size: 14px;
  }
}

@media screen and (max-width: 420px) {
  .navbar-logo-t-header {
    height: 26px;
  }
}

@media screen and (max-width: 375px) {
  .main-navbar .main-navbar-deposit {
    display: none;
  }
}
