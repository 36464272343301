.chevron-left {
	transform: scale(-1, 1);
}

.black-image {
	filter: brightness(0);
}

.svg-block {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.d-flex {
	display: flex;
}
.justify-center {
	justify-content: center;
}

.no-results {
	font-size: 16px;
	color: #BE8D46;
	margin: 0;
	@media screen and (max-width: 800px) {
		font-size: 14px;
	}
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.t-center {
	text-align: center;
}

.iframe-style {
	display: flex;
	iframe {
		border-radius: 12px;
		border: 1px solid #BE8D46;
		background: linear-gradient(0deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%), #0D1013;
		box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
		overflow: hidden;
	}
	@media screen and (max-width: 1320px) {
		&.content-py {
			padding-top: 0;
		}
	}
}

.info-tooltip {
	font-family: "Inter", sans-serif;
	&-item {
		&:not(:last-child) {
			padding-bottom: 12px;
			margin-bottom: 12px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.12);
		}
	}
}

.overflow-hidden {
	overflow: hidden;
}

iframe {
	border: 0;
}