.liveCasino {
	&-grid {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 30px;
	}
	&-play {
		position: absolute;
		top: 8px;
		right: 8px;
		display: flex;
		align-items: center;
		cursor: pointer;
		color: #FFF;
		font-size: 20px;
		letter-spacing: 0.063px;
		padding: 12px;
		border-radius: 8px;
		border: 1px solid rgba(255, 255, 255, 0.40);
		background: rgba(255, 255, 255, 0.08);
		transition: all .2s;
		opacity: 0;
		line-height: 1;
		.svg-block {
			width: auto;
		}
		svg {
			width: 24px;
			margin-inline-end: 8px;
		}
		&:hover {
			border-color: rgba(255, 255, 255, 0.56);
			background: rgba(255, 255, 255, 0.16);
			color: #fff;
		}
	}
	&-card {
		position: relative;
		border-radius: 12px;
		border: var(--card-border);
		overflow: hidden;
		transition: all .2s;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(8, 17, 27, 0.64);
			transition: all .2s;
		}

		&:hover {
			box-shadow: 0px 4px 32px 0px rgba(240, 178, 94, 0.40);
			border-color: #F0B25E;
			&::before {
				opacity: 0;
			}
			.liveCasino-play {
				opacity: 1;
			}
			.liveCasino-logo {
				top: 0;
				bottom: -70%;
			}
		}
	}
	&-logo {
		position: absolute;
		bottom: 0;
		left: 0;
		top: 0;
		right: 0;
		margin: auto;
		height: auto;
		object-fit: contain;
		transition: all .3s;
		transform: translateY(0);
		&.pragmatic {
			width: 65%;
		}
		&.evolution {
			width: 58%;
		}
		&.ezugi {
			width: 35%;
		}
		&.vivo {
			width: 65%;
		}
	}
	&-img {
		display: flex;
		align-items: center;
		img {
			width: 100%;
			height: auto;
		}
	}
}

@media (hover: none) {
	.liveCasino-play {
		display: none;
	}
}

@media screen and (max-width: 1320px) {}

@media screen and (max-width: 1070px) {
	.liveCasino-grid {
		grid-gap: 12px;
		grid-template-columns: repeat(3, 1fr);
	}
}

@media screen and (max-width: 600px) {
	.liveCasino-grid {
		grid-template-columns: repeat(2, 1fr);
	}
}
