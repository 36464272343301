.footer {
  padding: 64px 0;
	border-top: 1px solid rgba(255, 255, 255, 0.12);
	background: #0D1013;
  &-menu {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 130px;
		margin: 0 auto 48px;
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-block-end: 23px;
      width: 100%;
      text-align: left;
			cursor: initial;
      img {
        display: none;
      }
    }
    &-title {
      font-size: 20px;
      font-weight: 700;
      color: var(--classic-color);
      margin: 0;
      padding-inline-end: 15px;
    }
    &-link {
      color: rgba(255, 255, 255, 0.64);
      font-size: 16px;
      transition: .15s;
      text-align: left;
      &:hover, &.active {
        color: #fff;
      }
      &-block {
        display: flex;
        align-items: center;
        &:not(:last-child) {
          margin-block-end: 16px;
        }
      }
    }
  }
  &-slogan {
    position: relative;
    margin-block-end: 48px;
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		border-radius: 12px;
		border: var(--card-border);
		padding: 16px 24px;
		&-bg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: .9;
			background: linear-gradient(90deg, rgba(13,16,19,1) 0%, rgba(24,29,33,1) 100%);
		}
		&-line {
			background: rgba(255, 255, 255, 0.24);
			width: 1px;
			height: 100%;
			margin: 0 32px;
			flex-shrink: 0;
			flex-grow: 0;
			height: 54px;
		}
    &-content {
      display: flex;
      align-items: center;
      color: #fff;
			position: relative;
    }
    &-title {
      font-size: 24px;
      font-weight: 700;
      text-transform: uppercase;
    }
    &-text {
      font-size: 15px;
    }
    &-logo {
      height: 54px;
      width: auto;
    }
  }
  &-text {
		color: rgba(255, 255, 255, 0.64);
    font-size: 16px;
    line-height: 120%;
  }

  .block-mg {
    margin: 25px 0;
  }
}

@media screen and (max-width: 1550px) {
  .footer-menu {
    grid-gap: 75px;
  }
  .footer-menu-title {
    font-size: 18px;
  }
}

@media screen and (max-width: 1390px) {
  .footer {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .footer-menu {
    grid-gap: 60px;
  }
  .footer-menu-link-block:not(:last-child) {
    margin-block-end: 10px;
  }
}

@media screen and (max-width: 960px) {
	.footer-menu-title {
		font-size: 16px;
	}
	.footer-menu-header {
		margin-inline-end: 20px;
	}
	.footer-menu {
		grid-gap: 40px;
	}
	.footer-slogan-title {
		font-size: 22px;
	}
	.footer-slogan-logo,
	.footer-slogan-line {
		height: 47px;
	}
}

@media screen and (max-width: 835px) {
	.footer-menu-title {
		font-size: 16px;
	}
  .footer-menu {
    grid-template-columns: 1fr;
    grid-gap: 25px;
  }
  .footer-menu-header {
		margin-block-end: 0;
    img {
      display: block;
			width: 13px;
			height: auto;
			transform: rotate(0deg);
    }
  }
	.footer-menu-tab {
    display: none;
  }
	.footer-menu-block.active {
		.footer-menu-tab {
			display: block;
		}
		.footer-menu-header img {
			transform: rotate(-180deg);
		}
	}
	.footer-menu-link-block:not(:last-child) {
		margin-top: 25px;
		margin-bottom: 20px;
	}
	.footer-menu-link-block:first-child {
		margin-top: 20px;
	}
	.footer-text {
		font-size: 12px;
	}
	.footer-slogan, 
	.footer-menu {
		margin-block-end: 25px;
	}
}

@media screen and (max-width: 700px) {
  .footer {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 640px) {
	.footer-slogan-logo, .footer-slogan-line {
		height: 36px;
	}
	.footer-slogan-line {
		margin: 0 16px;
	}
	.footer-slogan-title {
		font-size: 16px;
	}
	.footer-slogan {
		padding: 12px;
	}
}

@media screen and (max-width: 400px) {
	.footer-slogan-logo, .footer-slogan-line {
		height: 30px;
	}
	.footer-slogan-title {
		font-size: 14px;
	}
}
