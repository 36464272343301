.profile {
	display: grid;
	grid-template-columns: 1fr 470px;
	grid-gap: 30px;
	&.no-grid {
		display: block;
	}
	&-pd {
		padding: 30px 25px;
	}
	&-settings {
		&-dob {
			position: relative;
			width: 100%;
			button {
				text-align: left;
				width: 100%;
				height: 40px;
				padding: 0 12px;
				color: #fff;
				font-size: 14px;
				span {
					color: rgba(255, 255, 255, 0.50);
				}
			}
			&-icon {
				position: absolute;
				top: 0;
				bottom: 0;
				width: 12px;
				height: auto;
				right: 12px;
				margin: auto 0;
			}
		}
		&-fields {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 30px;
			.theme-btn {
				min-width: 100px;
				width: auto;
			}
		}
		&-btns {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 30px;
			margin-block-start: 28px;
		}
	}
	&-verif {
		&-list {
			padding-inline-start: 0;
			margin-block-start: 15px;
			list-style: none;
			margin-block-end: 40px;
			li {
				position: relative;
				&:before {
					content: "\2022";  
					color: #EEAF5D;
					display: inline-block; 
					width: 3px; 
					margin-inline-end: 15px;
				}
			}
		}
		&-action {
			display: flex;
			align-items: flex-end;
			.inputGroup {
				min-width: 250px;
				margin-inline-end: 20px;
			}
		}
		&-upload {
			margin-inline-end: 20px;
			display: flex;
			align-items: center;
			.theme-btn-outlined {
				white-space: nowrap;
			}
		}
		&-imgName {
			max-width: 150px;
			width: 100%;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			flex-shrink: 0;
			flex-grow: 0;
			margin-inline-start: 20px !important;
		}
	}
	&-game {
		&-row {
			display: grid;
			align-items: center;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 20px;
		}
		&-fields {
			display: grid;
			grid-template-columns: 1fr;
			grid-gap: 20px;
		}
	}
}

@media screen and (max-width: 1450px) {
	.profile {
		grid-template-columns: 1fr 380px;
	}
}

@media screen and (max-width: 1320px) {
	.profile {
		&-pd {
			padding: 25px 20px;
		}
	}
}

@media screen and (max-width: 1110px) {
	.profile {
		grid-template-columns: 1fr;
	}
}

@media screen and (max-width: 900px) {
	.profile-verif-action {
		display: grid;
		grid-template-columns: 1fr;
	}
	.profile-verif-action .inputGroup {
		margin-inline-end: 0;
		margin-block-end: 20px;
	}
	.profile-verif-upload {
		margin-inline-end: 0;
		margin-block-end: 20px;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 15px;
		display: grid;
	}
	.profile-verif-imgName {
		max-width: 100%;
		margin-inline-start: 0 !important;
	}
}

@media screen and (max-width: 725px) {
	.profile-settings-fields {
		grid-template-columns: 1fr;
		grid-gap: 15px;
	}
	.profile-game-row,
	.profile-game-fields {
		grid-gap: 15px;
	}
	.profile-settings-btns {
		grid-gap: 15px;
	}
}

@media screen and (max-width: 550px) {
	.profile-settings-btns {
		grid-template-columns: 1fr;
	}
	.profile-pd {
		padding: 20px 20px;
	}
}

@media screen and (max-width: 450px) {
	.profile-game-row {
		grid-template-columns: 1fr;
	}
}

@media screen and (max-width: 420px) {
	.profile-verif-upload {
		grid-template-columns: 1fr;
	}
	.profile-verif-action {
		.theme-btn {
			width: 100%;
		}
	}
}

@media screen and (max-width: 400px) {
	.profile-settings-fields {
		.cabinet-blockBtn {
			flex-direction: column;
		}
		
	}
	
}