@import "./cabinetNav";
@import "./cabinetNavComponents";
@import "./financeForms";
@import "./paymentCard";
@import "./profile";

.cabinet {
  &-text {
    font-size: 16px;
    color: var(--classic-color);
    margin: 0;
    @media screen and (max-width: 1320px) {
      font-size: 14px;
    }
  }
  &-divider {
    width: 100%;
    height: 1px;
    background: linear-gradient(54deg, #eeaf5d 0%, rgba(2, 0, 36, 0) 100%);
  }
  &-btn {
    background: var(--theme-color);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    padding: 0 20px;
    height: 40px;
    min-width: 140px;
    width: 100%;
    position: relative;
    transition: 0.2s;
    &.outlined {
      // text inside button must be in span tag
      &:before {
        content: "";
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background: var(--cabinet-nav-bg);
        bottom: 0;
        inset-inline-end: 0;
        margin: auto;
        border-radius: 4px;
      }
      span {
        position: relative;
        color: var(--classic-color);
      }
      img {
        position: relative;
        margin-inline-end: 7px;
        width: 16px;
        height: 16px;
        object-fit: contain;
      }
    }
    &:hover {
      box-shadow: var(--button-filled-shadow-hover);
    }
  }
  &-block {
    border-radius: 12px;
    border: var(--card-border);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%), #0d1013;
    overflow: hidden;
  }
  &-blockBtn {
    display: flex;
    align-items: center;
  }
}
// old styles for typical deposit page
// .deposit {
// 	&:not(.no-grid) {
// 		display: grid;
// 		grid-template-columns: 1fr 460px;
// 		grid-gap: 30px;
// 	}
// 	&-grid {
// 		display: grid;
// 		grid-template-columns: repeat(3, 1fr);
// 		grid-gap: 20px;
// 		padding: 30px 25px;
// 	}
// }

.bankAccounts-add {
  padding: 15px;
  display: flex;
  justify-content: flex-end;
  .cabinet-btn {
    width: auto;
  }
}

// .deposit-grid::-webkit-scrollbar-thumb:horizontal {
//   background: var(--theme-color);
//   border-radius: 20px;
// }
// .deposit-grid::-webkit-scrollbar {
//   height: 4px;
// }
// .deposit-grid::-webkit-scrollbar-track {
//   border-radius: 3px;
//   background-color: var(--navbar-search-border);
// }

// @media screen and (max-width: 1550px) {
//   .deposit:not(.no-grid) {
//     grid-template-columns: 1fr 380px;
//   }
// }

// @media screen and (max-width: 1460px) {
//   .deposit-grid {
//     grid-template-columns: repeat(2, 1fr);
//   }
// }

// @media screen and (max-width: 1320px) {
//   .deposit-grid {
//     grid-template-columns: repeat(3, 1fr);
//     padding: 25px 20px;
//   }
// }

// @media screen and (max-width: 1150px) {
//   .deposit-grid {
//     grid-template-columns: repeat(2, 1fr);
//   }
//   .deposit:not(.no-grid) {
//     grid-template-columns: 1fr 300px;
//   }
// }

// @media screen and (max-width: 991px) {
//   .deposit:not(.no-grid) {
//     grid-template-columns: 1fr;
//   }

//   .deposit-grid {
//     grid-gap: 0;
//     display: flex;
//     flex-wrap: nowrap;
//     overflow-x: auto;
//     .paymentCard {
//       &:not(:last-child) {
//         margin-inline-end: 15px;
//       }
//     }
//   }
//   .deposit-scroll {
//     width: calc(100vw - 60px);
//   }
// }

// @media screen and (max-width: 700px) {
//   .deposit-scroll {
//     width: calc(100vw - 30px);
//   }
// }

@media screen and (max-width: 440px) {
  .cabinet-text {
    font-size: 14px;
  }
}
