.mailModal {
	.theme-modal-heading {
		border-bottom: 1px solid rgba(255, 255, 255, 0.12);
		background: #0D1013;
		padding: 30px;
		margin-bottom: 0;
	}
	.inputGroup {
		&:first-of-type {
			margin-bottom: 25px;
		}
	}
	.theme-btn {
		margin-top: 30px;
	}
	.custom-alert {
		border-radius: 4px;
		margin: 15px 0 0 0;
	}
}

@media screen and (max-width: 500px) {
	.mailModal .theme-modal-heading {
		padding: 20px;
	}
}