.about {
  &__banner {
    border-radius: 12px;
    border: var(--card-border);
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.48);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 65px 40px;
    &-content {
      max-width: 578px;
      width: 100%;
    }
    &-title {
      color: #fff;
      text-shadow: 0px 0px 29px #fff;
      font-size: 40px;
      font-weight: 600;
      letter-spacing: 0.132px;
    }
    &-text {
      color: rgba(255, 255, 255, 0.8);
      font-size: 14px;
      letter-spacing: 0.042px;
      span {
        color: #fff;
        font-weight: 700;
      }
    }
    &-line {
      height: 1.5px;
      background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      box-shadow: 0 0 24px rgba(255, 255, 255, 0.64);
      width: 100%;
      margin: 15px 0;
    }
    .btn-block {
      margin-top: 26px;
    }
  }
  &__ftr {
    border-radius: 12px;
    border: var(--card-border);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%), #0d1013;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
    padding: 80px;
    margin: 48px 0 110px;
    &-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 60px;
    }
    &-item {
      &-title {
        color: #fff;
        text-align: center;
        font-size: 22px;
        font-weight: 700;
        letter-spacing: 0.063px;
        margin-bottom: 12px;
        line-height: 130%;
      }
      &-text {
        color: rgba(255, 255, 255, 0.8);
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 133.023%;
        letter-spacing: 0.042px;
      }
    }
    &-icon {
      width: 110px;
      height: 110px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  &__support {
    padding: 52px 48px;
    border: var(--card-border);
    border-radius: 12px;
    display: grid;
    grid-template-columns: 1fr 430px;
    align-items: center;
    position: relative;
    background: linear-gradient(315deg, #0d1013 0%, #181d21 100%);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
    &-mask {
      position: absolute;
      top: 0;
      left: 0;
      width: auto;
      height: 100%;
    }
    &-img {
      position: absolute;
      right: -30px;
      top: -150px;
      width: 600px;
      height: auto;
    }
    &-logo {
      width: 216px;
      height: auto;
      margin-bottom: 48px;
    }
    &-title {
      text-align: left;
    }
    &-text {
      margin-bottom: 32px;
      text-align: left;
    }
    &-content {
      max-width: 537px;
      width: 100%;
    }
    &-block {
      position: relative;
    }
    &-mobileLogo {
      display: none;
    }
  }
  .casino-divider {
    display: none;
  }
}

@media screen and (max-width: 1320px) {
  .about__banner {
    background-position: center;
  }
  .about__ftr {
    padding: 50px 40px;
    margin: 48px 0;
  }
  .about__support {
    padding: 50px 40px;
  }
  .about__support-img {
    top: -47px;
    width: 500px;
  }
  .about {
    .casino-divider {
      display: block;
      margin-bottom: 48px;
    }
  }
}

@media screen and (max-width: 991px) {
  .about__ftr-item-title {
    font-size: 20px;
  }
}

@media screen and (max-width: 900px) {
  .about__support-img {
    display: none;
  }
  .about__support-logo {
    display: none;
  }
  .about__support-mobileLogo {
    display: block;
    width: 32px;
    position: absolute;
    top: 8px;
    right: 8px;
    svg {
      width: 100%;
      height: auto;
    }
  }
  .about__support-content {
    max-width: 600px;
  }
  .about__support {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 865px) {
  .about__ftr-grid {
    grid-template-columns: 1fr;
  }
  .about__ftr-grid {
    grid-gap: 45px;
  }
}

@media screen and (max-width: 500px) {
  .about__ftr,
  .about__banner,
  .about__support {
    padding: 30px 20px;
  }
  .about__banner-title {
    font-size: 30px;
  }
  .about .casino-divider {
    margin-bottom: 30px;
  }
  .about__ftr {
    margin: 30px 0;
  }
}
