.support {
  &-grid {
  }
  &-faq {
    overflow: hidden;
		box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
		border-radius: 12px;
		border: 1px solid #BE8D46;
    &-block {
			background: linear-gradient(0deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%), #0D1013;
      padding: 30px;
    }
    &-header {
      display: flex;
      align-items: center;
      background: #0D1013;
      padding: 30px;
      p {
        font-size: 24px;
        color: var(--classic-color);
        font-weight: 700;
        margin: 0;
      }
      img {
        margin-inline-end: 10px;
      }
    }
    &-quest {
      display: flex;
      width: 100%;
      text-align: left;
      align-items: center;
      img {
        width: 24px;
        height: 24px;
        margin-inline-end: 10px;
        transform: rotate(0deg);
        position: relative;
        &.active {
          transform: rotate(45deg);
        }
      }
      p {
        margin: 0;
        font-size: 16px;
        color: var(--classic-color);
        font-weight: 600;
      }
    }
    &-answ {
      color: #898b90;
      font-size: 14px;
      margin-block-start: 15px;
      padding-inline-start: 31px;
      display: none;
      &.active {
        display: block;
      }
    }
    &-divider {
      background: linear-gradient(
        54deg,
        #EEAF5D 0%,
        rgba(2, 0, 36, 0) 100%
      );
      width: 100%;
      height: 1px;
    }
  }
  &-contact {
    overflow: hidden;
		box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
		border-radius: 12px;
		border: var(--card-border);
    &-title {
      font-size: 20px;
      color: var(--classic-color);
      font-weight: 700;
			line-height: 1;
    }
		&-text {
			margin-top: 12px;
			color: rgba(255, 255, 255, 0.80);
			font-size: 16px;
			margin-bottom: 36px;
		}
		&-copy {
			width: 24px;
			height: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
			svg {
				width: 100%;
				height: 100%;
				object-fit: contain;
				&.MuiSvgIcon-root {
					fill: #EEAF5F;
				}
			}
		}
    &-group {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 20px;
      align-items: center;
      width: 100%;
			padding: 32px;
			border-radius: 8px;
			border: 1px solid rgba(255, 255, 255, 0.12);
			background: linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 100%), #0D1013;
      &:not(:last-child) {
        margin-block-end: 20px;
      }
			&-right {
				display: flex;
				align-items: center;
			}
      &-icon {
        display: flex;
        align-items: center;
				a {
					display: none;
				}
        img {
          width: 36px;
          height: 36px;
          margin-inline-end: 10px;
        }
      }
      &-title {
        font-size: 18px;
        margin: 0;
        color: var(--classic-color);
      }
      &-text {
        color: rgba(255, 255, 255, 0.8);
        font-size: 18px;
        text-decoration: none;
				margin-inline-end: 12px;
        &:hover {
          color: white;
        }
      }
    }
  }
}

@media screen and (max-width: 1320px) {
  .support-faq-header p {
    font-size: 20px;
  }
  .support-faq-header img {
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: 1030px) {
  .support-faq-header,
  .support-faq-block {
    padding: 20px;
  }
  .support-faq-header p,
  .support-contact-title {
    font-size: 17px;
  }
	.support-contact-group {
		padding: 20px;
	}
}

@media screen and (max-width: 700px) {
	.support-contact-group-right {
		a {
			display: none;
		}
	}
	.support-contact-group-icon {
		a {
			display: initial;
		}
	}
	.support-contact-group-text {
		margin-inline-end: 0;
		font-size: 14px;
	}
	.support-contact-group-title {
		font-size: 16px;
	}
	.support-contact-text {
		margin-bottom: 24px;
	}
}

@media screen and (max-width: 420px) {
	.support-contact-group {
		padding: 10px;
	}
	.support-contact-text {
		font-size: 14px;
	}
}

@media screen and (max-width: 375px) {
	.support-contact-group-icon img {
		display: none;
	}
	.support-contact-group {
		grid-gap: 10px;
	}
}