.cabinet-nav {
  border-radius: 6px;
  // background: grey;
  background: linear-gradient(90deg, #0d1013 0%, rgba(13, 16, 19, 0) 100%);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  border-left: 6px solid #eeaf5d;
  padding: 12px 30px;
  position: relative;
  display: flex;
  align-items: center;
  margin-block-end: 48px;

  &-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--classic-color);
    font-size: 20px;
    font-weight: 500;
    position: relative;
    transition: 0.15s;
    &:not(:last-child) {
      margin-inline-end: 55px;
    }
    &-icon {
      margin-inline-end: 5px;
      display: flex;
      align-items: center;
      svg {
        width: 40px;
        height: 40px;
        object-fit: contain;
        path {
          fill: #fff;
          transition: 0.15s;
        }
      }
    }
    &-chevron {
      position: absolute;
      inset-inline-end: 12px;
      top: 0;
      bottom: 0;
      margin: auto 0;
      width: 13px;
      height: 13px;
      filter: grayscale(1);
    }
    @media screen and (min-width: 992px) {
      &:hover,
      &.active {
        color: #eeaf5d;
        svg path {
          fill: #eeaf5d;
        }
      }
    }
  }
  &-m {
    display: none;

    &-selected {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%), #0d1013;
      border-radius: 5px;
      border: 1px solid var(--input-border-color);
      &.active {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      svg path {
        fill: #eeaf5d;
      }
    }
  }
  &-list {
    display: none;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%), #0d1013;
    border-inline-start: 1px solid var(--input-border-color);
    border-inline-end: 1px solid var(--input-border-color);
    border-bottom: 1px solid var(--input-border-color);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    &.active {
      display: block;
    }
  }
}

@media screen and (max-width: 1320px) {
  .cabinet-nav-link {
    font-size: 18px;
  }
  .cabinet-nav-link:not(:last-child) {
    margin-inline-end: 40px;
  }
  .cabinet-nav {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 991px) {
  .cabinet-nav {
    display: none;
  }
  .cabinet-nav-m {
    display: block;
    margin-block-end: 50px;
  }
  .cabinet-nav-link-icon svg {
    width: 30px;
    height: 30px;
  }
  .cabinet-nav-link:not(:last-child) {
    margin-inline-end: 0;
  }
  .cabinet-nav-link {
    padding: 9px 12px;
    font-size: 16px;
  }
  .cabinet-nav-link-icon {
    img {
      width: 18px;
      height: 18px;
    }
  }
}
