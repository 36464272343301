button {
  background: transparent;
  border: 0;
  padding: 0;
}

.btn-block {
  display: flex;
  align-items: center;
}

.theme-btn {
  padding: 8px 24px;
  // border-radius: 10px;
  border-radius: 4px;
  border: 1px solid #be8d46;
  background: linear-gradient(115deg, #be8d46 -0.2%, #97672e 7.01%, #c99149 22.74%, #f0b25e 44.21%, #ffbe66 49.49%, #eeaf5d 58.22%, #c08944 77.26%, #97672e 88.75%);
  box-shadow: 0px -6px 6px 0px rgba(0, 0, 0, 0.24) inset, 0px 0px 24px 0px rgba(255, 190, 102, 0.32);
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: 0.15s;
  &:hover {
    color: #fff;
    box-shadow: 0px -6px 6px 0px rgba(0, 0, 0, 0.24) inset, 0px 0px 32px 0px rgba(255, 190, 102, 0.56);
  }
  &.h-40 {
    padding-top: 0;
    padding-bottom: 0;
    height: 40px;
  }
  &.r-35 {
    border-radius: 35px;
  }
}

.theme-btn,
.theme-btn-outlined {
  &.full-width {
    width: 100%;
  }
}

.theme-btn-outlined {
  border-radius: 4px;
  border: 1px solid #be8d46;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 24px;
  transition: 0.15s;
  &:hover {
    box-shadow: 0px -6px 6px 0px rgba(0, 0, 0, 0.24) inset, 0px 0px 19px 0px rgba(255, 190, 102, 0.56);
  }
}

.inputGroup {
  .custom-alert {
    margin-bottom: 0;
    margin-top: 7px;
  }
  &-label {
    font-size: 16px;
    color: #fff;
    margin-block-end: 8px;
    line-height: 100%;
    @media screen and (max-width: 500px) {
      font-size: 14px;
      margin-block-end: 5px;
    }
  }
  .MuiInputBase-root {
    width: 100%;
    font-family: "Inter", sans-serif;
    font-size: 14px;
  }
  .Mui-focused,
  .MuiInputBase-root:hover {
    .MuiOutlinedInput-notchedOutline {
      border-color: rgba(255, 255, 255, 0.16) !important;
      border-width: 1px !important;
    }
  }
  .MuiOutlinedInput-notchedOutline {
    transition: 0.15s;
  }
  .MuiSvgIcon-root {
    color: #fff;
  }
  .MuiSelect-select {
    padding: 0 38px 0 12px;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.16) !important;
    background: #2e373e;
    color: #fff;
    height: 42px !important;
    display: flex;
    align-items: center;
  }
}
.placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.custom-select-dropdown {
  .MuiPaper-root {
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.16);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), #2e373e;
    color: #fff;
    font-family: "Inter", sans-serif;
    margin-top: 8px;
  }
  .MuiList-root {
    padding: 0 12px !important;
  }
  .MuiTouchRipple-root {
    display: none !important;
    background: transparent !important;
  }
  .MuiMenuItem-root {
    padding: 12px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    color: #b7b7b7;
    font-size: 14px;
    letter-spacing: 0.037px;
    display: flex;
    align-items: center;
    font-family: "Inter", sans-serif;
    min-width: 140px;
    transition: 0.15s;
    background: transparent;
    &:last-child {
      border-bottom: 0;
    }
    &.Mui-selected,
    &:hover,
    &.Mui-selected:hover {
      color: #eeaf5d;
      background: transparent !important;
    }
  }
}

.MuiAutocomplete-inputRoot {
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.16) !important;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), #2e373e;
  height: 42px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 12px !important;
  input {
    padding: 0 !important;
    color: #fff;
    height: 100%;
    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
      opacity: 1;
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }
}

.MuiAutocomplete-noOptions {
  border-radius: 4px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), #2e373e;
  padding: 12px 12px !important;
  border: 1px solid rgba(255, 255, 255, 0.16) !important;
  color: #fff !important;
  font-size: 14px;
  font-family: "Inter", sans-serif;
}

.MuiAutocomplete-option {
  padding: 12px 0 !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.037px;
  display: flex;
  align-items: center;
  font-family: "Inter", sans-serif;
  transition: 0.15s;
  background: transparent !important;
  &:hover,
  &[aria-selected="true"] {
    color: #eeaf5d;
    background: transparent !important;
  }
  &:last-of-type {
    border-bottom: 0;
  }
}

.MuiAutocomplete-listbox {
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), #2e373e;
  color: #fff;
  font-family: "Inter", sans-serif;
  padding: 0 12px !important;
}

.theme-input {
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.16) !important;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), #2e373e;
  overflow: hidden;
  width: 100%;
  transition: 0.15s;
  &:hover {
    border-color: rgba(255, 255, 255, 1) !important;
  }
  &.search-input:hover {
    border-color: rgba(255, 255, 255, 0.4) !important;
  }
  .MuiOutlinedInput-root,
  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }
  input {
    height: 40px;
    padding: 0 12px;
  }
  input,
  textarea {
    color: #fff;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    -webkit-text-fill-color: #fff !important;
    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}

.fix-text {
  color: red;
  font-size: 16px;
  margin-bottom: 20px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  border: 1px solid #21282c;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px #21282c inset;
  border-radius: 0;
  transition: 0.15s;
}

input:-webkit-autofill:focus {
  border: 1px solid #2e373e;
  -webkit-box-shadow: 0 0 0px 1000px #2e373e inset;
}
