.main-content {
	// background-color: var(--sidebar-block-bg);
	overflow: hidden;
	width: 100%;
	padding-bottom: 60px;
	background-repeat: repeat;
	background-size: 93px 93px;
	position: relative;
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: radial-gradient(circle, rgba(13,16,19,0.7399334733893557) 0%, rgba(13,16,19,0.896796218487395) 100%);
	}
	&-children {
		position: relative;
	}
	@media screen and (max-width: 700px) {
		padding-bottom: 40px;
	}
}

.layout {
	display: grid;
	grid-template-columns: 256px calc(100% - 256px);
	width: 100%;
	margin: 0 auto;
	@media screen and (max-width: 1320px) {
		grid-template-columns: 1fr;
	}
	&-content {
		min-height: 100vh;
		display: grid;
		grid-template-rows: auto 1fr auto;
		width: 100%;
		-ms-overflow-style: none;
		/* IE and Edge */
		scrollbar-width: none;
		background-color: var(--sidebar-block-bg);
		/* Firefox */
		&::-webkit-scrollbar {
			display: none;
		}
	}
}

.page {
	&-title {
		font-size: 40px;
		color: var(--classic-color);
		margin-block-end: 40px;
		font-weight: 600;
		letter-spacing: 0.132px;
		&:not(.keep-in-view) {
			@media screen and (max-width: 1320px) {
				display: none;
			}
		}
		@media screen and (max-width: 991px) {
			font-size: 30px;
			margin-block-end: 25px;
		}
	}
}

.content-px {
	padding-inline-start: 30px;
	padding-inline-end: 30px;
	@media screen and (max-width: 700px) {
		padding-inline-start: 20px;
		padding-inline-end: 20px;
	}
}
.content-py {
	padding-top: 30px;
	@media screen and (max-width: 700px) {
		padding-top: 20px;
	}
}

.on-desktop {
	@media screen and (max-width: 1320px) {
		display: none !important;
	}
}

.on-tablet {
	@media screen and (min-width: 1321px) {
		display: none !important;
	}
}

.on-mobile {
	@media screen and (min-width: 701px) {
		display: none !important;
	}
}

.uw-content {
	max-width: 1740px;
	margin: 0 auto;
	width: 100%;
}

.page-loading {
	display: flex;
	align-items: center;
	justify-content: center;
	&-loader {
		border: 1.5px solid rgba(255, 255, 255, 0.1);
		border-top: 1.5px solid #EEAF5D;
		border-radius: 50%;
		width: 70px;
		height: 70px;
		animation: spin 1s linear infinite;
	}
}

.ant-notification-notice {
	border-radius: 8px;
	padding: 16px;
	border: 1px solid #F04438;
	background: linear-gradient(0deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%), #0D1013;
	box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.25) inset, 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
}
.ant-notification-notice-message {
	color: #fff;
	font-weight: 600;
}
.ant-notification-notice-description {
	color: rgba(255, 255, 255, 0.80);
}
.ant-notification-notice-close-icon {
	color: white;
}
.ant-notification-notice-success {
	border-color: #52c41b;
}

.preloader {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #0D1013;
	z-index: 100;
	.page-loading {
		margin: 0;
	}
}

.error-text {
	font-size: 12px;
	color: rgb(236, 78, 78);
}
