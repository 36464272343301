.authPage {
	background: #222;
	padding-top: 30px;
	padding-bottom: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	background-size: cover;
	background-position: center;
	position: relative;
	&__video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: radial-gradient(77.52% 54.69% at 50% 50%, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.35) 100%), radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.75) 100%);
		}
		video {
			width: 100%;
			height: 100%;
			object-fit: cover;
			background-size: cover;
			opacity: 0.05;
		}
	}
	&__content {
		max-width: 560px;
		width: 100%;
		border-radius: 16px;
		border: 1px solid rgba(229, 167, 88, 0.64);
		background: rgba(13, 16, 19, 0.64);
		box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.25) inset, 0px 8px 32px 0px rgba(0, 0, 0, 0.48);
		padding: 48px;
		position: relative;
	}
	&__contact {
		&-block {
			border-radius: 10px;
			background: rgba(255, 255, 255, 0.04);
			padding: 24px;
		}
		&-text {
			text-decoration: none;
			text-align: center;
			font-size: 18px;
			color: #fff;
			span {
				color: #EEAF5D;
				font-size: 20px;
			}
			b {
				font-weight: 600;
			}
		}
	}
	&__link {
		font-size: 14px;
		color: #fff;
		font-weight: 500;
		&.forgot-pass {
			margin-top: 24px;
			display: flex;
		}
		&.forgot-text {
			max-width: 295px;
			width: 100%;
			text-align: center;
			margin: 0 auto 32px;
		}
		a, button {
			color: #EEAF5D;
			&:hover {
				text-decoration: underline;
			}
		}
		button {
			font-weight: 500;
		}
		a {
			text-decoration: none;
		}
		&.under-button {
			text-align: center;
			margin-top: 32px;
		}
	}
	&__submit {
		margin-top: 48px;
	}
	&__btn {
		border-radius: 10px;
		border: 1px solid #BE8D46;
		background: #0D1013;
		box-shadow: 0px 4px 70px 0px rgba(192, 137, 68, 0.15), 0px 0px 40px 0px rgba(0, 0, 0, 0.25) inset;
		width: 100%;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: .15s;
		&:hover {
			box-shadow: 0px 4px 80px 0px rgba(192, 137, 68, 0.32), 0px 0px 40px 0px rgba(0, 0, 0, 0.25) inset;
		}
		&:disabled {
			cursor: not-allowed;
		}
		&-loader {
			border: 1.5px solid rgba(255, 255, 255, 0.1);
			border-top: 1.5px solid #EEAF5D;
			border-radius: 50%;
			width: 20px;
			height: 20px;
			animation: spin 1s linear infinite;
			margin-inline-end: 6px;
		}
		span {
			font-size: 16px;
			font-weight: 700;
			text-transform: uppercase;
			background: linear-gradient(90deg, #BF953F 0%, #FCF6BA 24.88%, #B38728 49.55%, #FBF5B7 75.44%, #AA771C 100%);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}
	&__forgotIcon {
		width: 56px;
		height: 56px;
		border-radius: 10px;
		border: 1px solid #BE8D46;
		box-shadow: 0px 4px 70px 0px rgba(192, 137, 68, 0.15), 0px 0px 40px 0px rgba(0, 0, 0, 0.25) inset;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto 16px;
	}
	&__forgotClose {
		width: 48px;
		height: 48px;
		border-radius: 8px;
		border: 1px solid rgba(255, 255, 255, 0.08);
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: -40px;
		right: -40px;
		transition: .15s;
		span {
			@media screen and (min-width: 581px) {
				display: none;
			}
		}
		&:hover {
			background: rgba(255, 255, 255, 0.04);
			border-color: rgba(255, 255, 255, 0.24);

		}
		svg {
			width: 50%;
			height: auto;
			flex-shrink: 0;
			flex-grow: 0;
		}
	}
	&__forgotForm {
		position: relative;
	}
	&__forgotLine {
		display: none;
		width: 100%;
		height: 1px;
		background-color: rgba(255, 255, 255, 0.12);
		margin: 16px 0;
	}
	&__forgotTitle {
		display: none;
	}
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

body .custom-alert {
	font-family: "Inter", sans-serif;
	margin: 0 0 24px 0;
	color: #FDA29B;
	border-radius: 10px;
	border: 1px solid #912018;
	background: rgba(122, 39, 26, 0.24) !important;
	box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.25) inset, 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
	min-width: initial !important;
}

.auth-textfield {
	box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.25) inset, 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
	&.mb {
		margin-bottom: 32px;
	}
	&:hover {
		.MuiInputBase-root {
			border-color: rgba(255, 255, 255, 0.16);
		}
	}
	.MuiInputBase-root {
		overflow: hidden;
		border-radius: 10px;
		padding-left: 0;
		height: 50px;
		color: #fff;
		transition: .15s;
		border: 1px solid transparent;
		background: #21282c;
		&.Mui-focused {
			background: #2E373E;
			border-color: rgba(255, 255, 255, 0.16);
		}
	}
	.MuiOutlinedInput-notchedOutline {
		border-width: 0 !important;
	}
	.MuiOutlinedInput-input:-webkit-autofill {
		border-radius: 0 !important;
	}
	.MuiInputAdornment-root {
		width: 50px;
		height: 50px;
		max-height: initial;
		justify-content: center;
		margin-inline-end: 12px;
		background: rgba(255, 255, 255, 0.10);
	}
	input {
		height: 50px;
		font-family: "Inter", sans-serif;
		font-size: 14px;
		padding: 0;
	}
}

@media screen and (max-width: 580px) {
	.authPage__content, .authPage__contact-block {
		padding: 24px 20px;
	}
	.authPage__contact-text,
	.authPage__contact-text span {
		font-size: 16px;
	}
	.authPage__btn span {
		font-size: 15px;
	}
	.authPage__forgotClose {
		position: static;
		width: auto;
		padding: 8px;
		margin-inline-start: auto;
		span {
			color: #fff;
			font-size: 16px;
		}
		.svg-block {
			margin-inline-end: 8px;
			width: 22px;
			height: 22px;
		}
		svg {
			width: 100%;
			height: 100%;
		}
	}
	.authPage__forgotLine {
		display: block;
	}
	.authPage__forgotTitle-content {
		display: grid;
		grid-template-columns: 1fr auto;
		grid-gap: 20px;
	}
	.authPage__forgotTitle {
		display: block;
		font-size: 20px;
		font-weight: 700;
		margin-bottom: 8px;
		color: #fff;
	}
	.authPage__forgotIcon {
		order: 2;
		width: 44px;
		height: 44px;
		margin-bottom: 0;
	}
	.authPage__link.forgot-text {
		max-width: initial;
		text-align: left;
		margin-inline-start: initial;
	}
}

@media screen and (max-width: 360px) {
	.authPage__forgotTitle {
		font-size: 18px;
	}
	.authPage__forgotIcon {
		width: 40px;
		height: 40px;
	}
}

@media screen and (max-width: 350px) {
	.authPage__content, .authPage__contact-block {
		padding: 20px 15px;
	}
}

